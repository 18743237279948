import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Event from "admin/router/event";
import News from "admin/router/news";
import BlueLetter from "admin/router/blueletter";
import PortfolioAdmin from "admin/router/portfolio/PortfolioAdmin";
import StaffDetail from "router/staff-detail/StaffDetail";
import Insight from "router/insight/Insight";
import InsightEvents from "router/insight/Events";
import InsightContents from "router/insight/Contents";
import InsightNews from "router/insight/News";
import InsightBlueletter from "router/insight/Blueletter";
import EventDetail from "router/insight/EventDetail";
import ContentsDetail from "router/insight/ContentsDetail";
import Apply from "router/contact/Apply";
import PortfolioDetail from "router/portfolio-detail/PortfolioDetail";
import Partnership from "router/contact/Partnership";
import PrivacyPolicy from "router/policy/PrivacyPolicy";
import BannerAdmin from "admin/router/banner/BannerAdmin";
import AboutBluePoint from "router/about/AboutBluePoint";
import StartPoint from "router/program/StartPoint";
import Alumni from "router/program/Alumni";
import BlueLabs from "router/program/BlueLabs";
import BlueSchool from "router/program/BlueSchool";
import OpenInnovation from "router/program/OpenInnovation";
import Program from "router/program/Program";
import PortfolioHeaderList from "admin/router/portfolio-header-list/PortfolioHeaderList";
import Faq from "router/contact/Faq";
import HeaderNavigationNew from "component/header/HeaderNavigationNew";
import { NavigationMenu } from "admin/type/type";

import ReactGA from "react-ga4";
import PDFTestView from "PDFTestView";
import Maintainance from "router/maintainance/Maintainance";
import { useEffect, useState } from "react";
import ExternalApply from "router/contact/ExternalApply";
import DisclosureInfo from "router/ir/organism/disclosure-infomation/DisclosureInfo";
import StockInfomation from "router/ir/organism/stock-infomation/StockInfomation";
import IRData from "router/ir/organism/ir-data/IRArchives";
import Announcement from "router/ir/organism/announcement/\bAnnouncement";
import IRArchive from "admin/router/ir/IRArchive";
import IRAnnouncement from "admin/router/ir/IRAnnouncement";
import PopupAdmin from "admin/router/popup/PopupAdmin";
import AnnouncementDetail from "router/ir/organism/announcement/AnnouncementDetail";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setInitAxioSetting } from "./api/api";
import Main from "./router/main";

import Team from "./router/team";
import Portfolio from "./router/portfolio";
import NotFound from "./router/notFound";
import LogIn from "./admin/router/logIn";
import TeamAdmin from "./admin/router/team";
import Contents from "./admin/router/content";

// const trakingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
// if (process.env.NODE_ENV === "production") {
//   if (trakingId) {
//     ReactGA.initialize(trakingId);
//   }
// }

function App() {
  const [externalPage, updateExternalPage] = useState<boolean>(false);

  setInitAxioSetting();
  window.onpopstate = () => {
    localStorage.setItem("goBack", JSON.stringify({ goBack: true }));
  };
  console.log(process.env.REACT_APP_MAINTAINANCE);

  const isExternalPage = () => {
    return window.location.pathname.split("/")[1] === "external-apply";
  };

  useEffect(() => {
    updateExternalPage(isExternalPage());
  });

  return (
    <>
      <Router>
        {!externalPage && (
          <HeaderNavigationNew selected={NavigationMenu.MAIN} />
        )}
        {process.env.REACT_APP_MAINTAINANCE === "true" ? (
          <Routes>
            <Route path="/" element={<Maintainance />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/main" element={<Main />} />

            <Route path="/about" element={<AboutBluePoint />} />

            <Route path="/program" element={<Program />} />
            <Route path="/program/start-point" element={<StartPoint />} />
            <Route path="/program/alumni" element={<Alumni />} />
            <Route path="/program/bluelabs" element={<BlueLabs />} />
            <Route path="/program/blueschool" element={<BlueSchool />} />
            <Route
              path="/program/open-innovation"
              element={<OpenInnovation />}
            />

            <Route path="/team" element={<Team />} />
            <Route path="/staff/:id" element={<StaffDetail />} />

            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/portfolio/:id" element={<PortfolioDetail />} />

            <Route path="/insight" element={<Insight />} />
            <Route path="/insight/events" element={<InsightEvents />} />
            <Route path="/insight/events/:id" element={<EventDetail />} />
            <Route path="/insight/contents" element={<InsightContents />} />
            <Route path="/insight/contents/:id" element={<ContentsDetail />} />
            <Route path="/insight/news" element={<InsightNews />} />
            <Route path="/insight/blueletter" element={<InsightBlueletter />} />

            <Route path="/contact/apply" element={<Apply />} />
            <Route path="/contact/partnership" element={<Partnership />} />
            <Route path="/contact/faq" element={<Faq />} />
            {/* <Route path="/ir/disclosure-info" element={<DisclosureInfo />} />
          <Route path="/ir/stock-info" element={<StockInfomation />} />
          <Route path="/ir/ir-archive" element={<IRData />} />
          <Route path="/ir/announcement" element={<Announcement />} />
          <Route path="/ir/announcement/:id" element={<AnnouncementDetail />} /> */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            <Route path="/dash" element={<LogIn />} />
            <Route path="/dash/team" element={<TeamAdmin />} />
            <Route path="/dash/portfolio" element={<PortfolioAdmin />} />
            <Route
              path="/dash/portfolio/header-list"
              element={<PortfolioHeaderList />}
            />
            <Route path="/dash/contents" element={<Contents />} />
            <Route path="/dash/event" element={<Event />} />
            <Route path="/dash/news" element={<News />} />
            <Route path="/dash/blueletter" element={<BlueLetter />} />
            <Route path="/dash/banner" element={<BannerAdmin />} />

            <Route path="/pdf-test" element={<PDFTestView />} />

            <Route path="/external-apply/:code" element={<ExternalApply />} />

            {/* ADMIN IR */}
            <Route path="/dash/ir-archives" element={<IRArchive />} />
            <Route path="/dash/announcement" element={<IRAnnouncement />} />
            <Route path="/dash/popup" element={<PopupAdmin />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Router>
      <ToastContainer />
    </>
  );
}

/**
 /external-apply/eyJjaGFubmVsTmFtZSI6IkdT66as7YWM7J28In0=
 */

export default App;
