import styled from "@emotion/styled";
import AccordionMenu from "admin/component/accordion/AccordionMenu";
import { InsightMenu, NavigationMenu } from "admin/type/type";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DisplayType from "utils/displayType";

import { ReactComponent as Arrow } from "../../assets/pagenation_next.svg";
import { ReactComponent as MobileGoLink } from "../../assets/btn_golink_mobile.svg";

interface NavigationTheme {
  selected: NavigationMenu;
  isScrolltop?: boolean;
  isMenuOpen?: boolean;
  refreshPage?: (selectedNavigation: NavigationMenu) => void;
}
const MobileNavigations: React.FC<NavigationTheme> = ({
  selected,
  isScrolltop,
  isMenuOpen,
  refreshPage,
}) => {
  const mode = DisplayType();
  const [showModal, setShowModal] = useState(false);
  const [isAboutShow, updateAboutShow] = useState(false);
  const [isProgramShow, updateProgramShow] = useState(false);
  const [isInsightShow, updateInsightShow] = useState(false);
  const [isContactShow, updateContactShow] = useState(false);
  const [isIrShow, updateIrShow] = useState(false);

  const navigationItemStyle: React.CSSProperties = {
    flexGrow: 1,
    textDecoration: "none",
  };

  const onClickInsight = (value: InsightMenu, selectedMenu: NavigationMenu) => {
    localStorage.setItem("insightMenu", value);
    if (refreshPage) refreshPage(selectedMenu);
  };

  useEffect(() => {
    if (mode !== "Mobile") return;
    const body = document.body;
    if (isMenuOpen) {
      updateAboutShow(
        selected === NavigationMenu.ABOUT ||
          selected === NavigationMenu.COMPANY ||
          selected === NavigationMenu.TEAM ||
          selected === NavigationMenu.TEAMDETAIL
      );
      updateProgramShow(
        selected === NavigationMenu.PROGRAM ||
          selected === NavigationMenu.STARTINGPOINT ||
          selected === NavigationMenu.ALUMNI ||
          selected === NavigationMenu.BLUELABS ||
          selected === NavigationMenu.BLUESCHOOL ||
          selected === NavigationMenu.OPENINNOVATION
      );
      updateInsightShow(
        selected === NavigationMenu.INSIGHT ||
          selected === NavigationMenu.EVENTS ||
          selected === NavigationMenu.CONTENTS ||
          selected === NavigationMenu.NEWS ||
          selected === NavigationMenu.BLUELETTER
      );
      updateContactShow(
        selected === NavigationMenu.CONTACT ||
          selected === NavigationMenu.APPLY ||
          selected === NavigationMenu.PARTNERSHIP ||
          selected === NavigationMenu.FAQ
      );
      updateIrShow(
        selected === NavigationMenu.IR ||
          selected === NavigationMenu.DISCLOSURE_INFOMATION ||
          selected === NavigationMenu.STOCK_INFOMATIOIN ||
          selected === NavigationMenu.IR_DATA ||
          selected === NavigationMenu.ANNOUNCEMENT
      );
      body.style.overflow = "hidden";
    } else {
      body.style.overflow = "auto";
    }
  }, [isMenuOpen]);
  return (
    <Container
      style={{ top: isMenuOpen ? "60px" : "-100vh", paddingBottom: "60px" }}
    >
      <div className="navigation__item__wrapper">
        <NavigationItem
          depth={1}
          isSelected={
            NavigationMenu.ABOUT === selected ||
            selected === NavigationMenu.COMPANY ||
            selected === NavigationMenu.TEAM ||
            selected === NavigationMenu.TEAMDETAIL
          }
        >
          <Link
            to="/about"
            className="font-button1-en link__text"
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage && refreshPage(NavigationMenu.ABOUT)
            }
          >
            About
          </Link>
          <div
            className={`icon ${isAboutShow && "show"}`}
            onClick={() => updateAboutShow(!isAboutShow)}
          >
            <Arrow width={24} height={24} />
          </div>
        </NavigationItem>

        <InnerNavigationContainer isShow={isAboutShow}>
          <InnerNavigationItem isSelected={selected === NavigationMenu.COMPANY}>
            <Link
              to="/about"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() => refreshPage && refreshPage(NavigationMenu.ABOUT)}
            >
              회사소개
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem isSelected={selected === NavigationMenu.TEAM}>
            <Link
              to="/team"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() => refreshPage && refreshPage(NavigationMenu.TEAM)}
            >
              팀 소개
            </Link>
          </InnerNavigationItem>
        </InnerNavigationContainer>
      </div>
      <div className="navigation__item__wrapper">
        <NavigationItem
          depth={1}
          isSelected={
            selected === NavigationMenu.PROGRAM ||
            selected === NavigationMenu.STARTINGPOINT ||
            selected === NavigationMenu.ALUMNI ||
            selected === NavigationMenu.BLUELABS ||
            selected === NavigationMenu.BLUESCHOOL ||
            selected === NavigationMenu.OPENINNOVATION
          }
        >
          <Link
            to="/program"
            className="font-button1-en link__text"
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.PROGRAM)}
          >
            Program
          </Link>
          <div
            className={`icon ${isProgramShow && "show"}`}
            onClick={() => updateProgramShow(!isProgramShow)}
          >
            <Arrow width={24} height={24} />
          </div>
        </NavigationItem>
        <InnerNavigationContainer isShow={isProgramShow}>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.STARTINGPOINT}
          >
            <Link
              to="/program/start-point"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.STARTINGPOINT)
              }
            >
              시작점
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem isSelected={selected === NavigationMenu.ALUMNI}>
            <Link
              to="/program/alumni"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() => refreshPage && refreshPage(NavigationMenu.ALUMNI)}
            >
              동창
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.BLUELABS}
          >
            <Link
              to="/program/bluelabs"
              className="font-button1-en link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.BLUELABS)
              }
            >
              블루랩스
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.BLUESCHOOL}
          >
            <Link
              to="/program/blueschool"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.BLUESCHOOL)
              }
            >
              블루스쿨
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.OPENINNOVATION}
          >
            <Link
              to="/program/open-innovation"
              className="font-button1-en link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.OPENINNOVATION)
              }
            >
              오픈이노베이션
            </Link>
          </InnerNavigationItem>
        </InnerNavigationContainer>
      </div>
      <NavigationItem
        depth={1}
        isSelected={selected === NavigationMenu.PORTFOLIO}
      >
        <Link
          to="/portfolio"
          className="font-button1-en link__text"
          style={navigationItemStyle}
          onClick={() => refreshPage && refreshPage(NavigationMenu.PORTFOLIO)}
        >
          Portfolio
        </Link>
      </NavigationItem>
      <div className="navigation__item__wrapper">
        <NavigationItem
          depth={1}
          isSelected={
            selected === NavigationMenu.INSIGHT ||
            selected === NavigationMenu.EVENTS ||
            selected === NavigationMenu.CONTENTS ||
            selected === NavigationMenu.NEWS ||
            selected === NavigationMenu.BLUELETTER
          }
        >
          <Link
            to="/insight"
            className="font-button1-en link__text"
            style={navigationItemStyle}
            onClick={() => {
              onClickInsight(InsightMenu.ALL, NavigationMenu.INSIGHT);
            }}
          >
            Insight
          </Link>
          <div
            className={`icon ${isInsightShow && "show"}`}
            onClick={() => updateInsightShow(!isInsightShow)}
          >
            <Arrow width={24} height={24} />
          </div>
        </NavigationItem>
        <InnerNavigationContainer isShow={isInsightShow}>
          <InnerNavigationItem isSelected={selected === NavigationMenu.EVENTS}>
            <Link
              to="/insight/events"
              className="font-button1-en link__text"
              style={navigationItemStyle}
              onClick={() => {
                onClickInsight(InsightMenu.EVENTS, NavigationMenu.EVENTS);
              }}
            >
              Events
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.CONTENTS}
          >
            <Link
              to="/insight/contents"
              className="font-button1-en link__text"
              style={navigationItemStyle}
              onClick={() => {
                onClickInsight(InsightMenu.CONTENTS, NavigationMenu.CONTENTS);
              }}
            >
              Contents
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem isSelected={selected === NavigationMenu.NEWS}>
            <Link
              to="/insight/news"
              className="font-button1-en link__text"
              style={navigationItemStyle}
              onClick={() => {
                onClickInsight(InsightMenu.NEWS, NavigationMenu.NEWS);
              }}
            >
              News
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.BLUELETTER}
          >
            <Link
              to="/insight/blueletter"
              className="font-button1-en link__text"
              style={navigationItemStyle}
              onClick={() => {
                onClickInsight(
                  InsightMenu.BLUELETTER,
                  NavigationMenu.BLUELETTER
                );
              }}
            >
              Blue Letter
            </Link>
          </InnerNavigationItem>
        </InnerNavigationContainer>
      </div>

      <div className="navigation__item__wrapper">
        <NavigationItem
          depth={1}
          isSelected={
            selected === NavigationMenu.CONTACT ||
            selected === NavigationMenu.APPLY ||
            selected === NavigationMenu.PARTNERSHIP ||
            selected === NavigationMenu.FAQ
          }
        >
          <Link
            to="/contact/apply"
            className="font-button1-en link__text"
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.CONTACT)}
          >
            Contact
          </Link>
          <div
            className={`icon ${isContactShow && "show"}`}
            onClick={() => updateContactShow(!isContactShow)}
          >
            <Arrow width={24} height={24} />
          </div>
        </NavigationItem>
        <InnerNavigationContainer isShow={isContactShow}>
          <InnerNavigationItem isSelected={selected === NavigationMenu.APPLY}>
            <Link
              to="/contact/apply"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() => refreshPage && refreshPage(NavigationMenu.APPLY)}
            >
              Apply
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.PARTNERSHIP}
          >
            <Link
              to="/contact/partnership"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.PARTNERSHIP)
              }
            >
              Partnership
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem isSelected={selected === NavigationMenu.FAQ}>
            <Link
              to="/contact/faq"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() => refreshPage && refreshPage(NavigationMenu.FAQ)}
            >
              FAQ
            </Link>
          </InnerNavigationItem>
        </InnerNavigationContainer>
      </div>
      {/* 
      <div className="navigation__item__wrapper">
        <NavigationItem
          depth={1}
          isSelected={
            selected === NavigationMenu.DISCLOSURE_INFOMATION ||
            selected === NavigationMenu.STOCK_INFOMATIOIN ||
            selected === NavigationMenu.IR_DATA ||
            selected === NavigationMenu.ANNOUNCEMENT
          }
        >
          <Link
            // to="/ir/disclosure-info"
            to="/ir/announcement"
            className="font-button1-en link__text"
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.DISCLOSURE_INFOMATION)
            }
          >
            IR
          </Link>
          <div
            className={`icon ${isIrShow && "show"}`}
            onClick={() => updateIrShow(!isIrShow)}
          >
            <Arrow width={24} height={24} />
          </div>
        </NavigationItem>
        <InnerNavigationContainer isShow={isIrShow}> */}
      {/* <InnerNavigationItem
            isSelected={selected === NavigationMenu.DISCLOSURE_INFOMATION}
          >
            <Link
              to="/ir/disclosure-info"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.DISCLOSURE_INFOMATION)
              }
            >
              공시정보
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem
            isSelected={selected === NavigationMenu.STOCK_INFOMATIOIN}
          >
            <Link
              to="/ir/stock-info"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.STOCK_INFOMATIOIN)
              }
            >
              주가정보
            </Link>
          </InnerNavigationItem>
          <InnerNavigationItem isSelected={selected === NavigationMenu.IR_DATA}>
            <Link
              to="/ir/ir-archive"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() => refreshPage && refreshPage(NavigationMenu.IR_DATA)}
            >
              IR자료실
            </Link>
          </InnerNavigationItem> */}
      {/* <InnerNavigationItem
            isSelected={selected === NavigationMenu.ANNOUNCEMENT}
          >
            <Link
              to="/ir/announcement"
              className="font-button1 link__text"
              style={navigationItemStyle}
              onClick={() =>
                refreshPage && refreshPage(NavigationMenu.ANNOUNCEMENT)
              }
            >
              공고
            </Link>
          </InnerNavigationItem>
        </InnerNavigationContainer>
      </div> */}

      <div className="navigation__item__wrapper">
        <NavigationItem depth={1} isSelected={false}>
          <a
            href="https://bluecareer.co.kr/"
            target={"_blank"}
            rel="noreferrer"
            style={navigationItemStyle}
            className="font-button1-en link__text"
          >
            Recruit
          </a>
          <a
            className={`icon`}
            href="https://bluecareer.co.kr/"
            target={"_blank"}
            rel="noreferrer"
          >
            <MobileGoLink
              className="mobile-go-link"
              style={{
                transform: "rotate(270deg)",
              }}
            />
          </a>
        </NavigationItem>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 0;
  background-color: white;
  transition: all 0.2s ease;
  z-index: -1;
  overflow: scroll;

  .navigation__item__wrapper {
    display: flex;
    flex-direction: column;
  }
`;
const NavigationItem = styled.div<{
  depth: number;
  isSelected: boolean;
}>`
  position: relative;
  width: ${(props) => `calc(100% - ${24 * props.depth}px)`};
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${(props) => `${24 * props.depth}px`};
  .link__text {
    color: ${(props) => (props.isSelected ? "var(--primary)" : "var(--black)")};
  }
  .icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    transform: rotate(90deg);
    path {
      stroke: var(--grey-40);
    }
  }
  .icon.show {
    transform: rotate(270deg);
  }
  .mobile-go-link {
    path {
      stroke-width: 2;
    }
  }
`;

export const InnerNavigationContainer = styled.ul<{ isShow: boolean }>`
  display: ${(props) => (props.isShow ? "flex" : "none")};
  flex-direction: column;
  background-color: var(--bg-03);
  margin: 0;
  padding-left: 40px;
`;
export const InnerNavigationItem = styled.div<{ isSelected: boolean }>`
  height: 60px;
  min-height: 60px;
  max-height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  .link__text {
    color: ${(props) =>
      props.isSelected ? "var(--primary)" : "var(--grey-60)"};
  }
`;
export default MobileNavigations;
