import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Editor } from "@tinymce/tinymce-react";
import DisplayType from "utils/displayType";
import Spinner from "../../../component/Spinner/index";

interface Props {
  className?: string;
  initialValue: string;
}

const PopupEditor: React.FC<Props> = (props) => {
  const mode = DisplayType();
  const { className = "" } = props;
  const [loadContents, updateLoadContents] = useState<boolean>(false);
  const editorRef = useRef<any>();
  const key = process.env.REACT_APP_TINY_MCE_KEY;

  return (
    <EditorContainer
      className={`scroll__invisible ${className}`}
      isLoaded={loadContents}
    >
      <Editor
        ref={editorRef}
        apiKey={key}
        initialValue={props.initialValue}
        disabled
        inline={false}
        init={{
          language: "ko_KR",
          placeholder: "내용을 입력해주세요",
          height: "100%",
          skin: "borderless",
          toolbar: false,
          menubar: false,
          statusbar: false,
          content_style:
            mode === "Mobile" ? "img { width: 100%; height: auto;  }" : "",
          plugins: [
            "advlist autolink lists link image",
            "charmap print preview anchor help",
            "searchreplace visualblocks code",
            "insertdatetime media table paste wordcount autoresize",
          ],
        }}
        onLoadContent={() => {
          updateLoadContents(true);
        }}
      />
      {!loadContents && (
        <div className="progress">
          <Spinner loading size={120} />
        </div>
      )}
    </EditorContainer>
  );
};

const EditorContainer = styled.div<{ isLoaded: boolean }>`
  position: relative;
  width: calc(100%);
  height: calc(740px - 100px);
  padding: 12px;
  overflow: scroll;

  .tox-tinymce {
    height: 100%;
    opacity: ${(props) => (props.isLoaded ? "1" : "0")};
    border: 0 solid transparent;
    box-shadow: none;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    overflow: hidden;
    position: relative;
    visibility: inherit !important;
  }
  .tox .tox-edit-area__iframe {
    background-color: transparent;
    border: 0;
    box-sizing: border-box;
    flex: 1;
    -ms-flex-preferred-size: auto;
    height: 100%;
    position: absolute;
    width: 100%;
    user-select: none;
  }
  .progress {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 120px;
    height: fit-content;
    background-color: transparent;
  }

  @media only screen and (max-width: 768px) {
    height: calc(540px - 100px);
    padding: 0;
  }
`;
export default PopupEditor;
