import React, { forwardRef, useEffect, useRef, useState } from "react";
import { NodeModel } from "@minoru/react-dnd-treeview";
import { toast } from "react-toastify";

import axios from "axios";

import { NotionInput } from "admin/component/input/NotionInput";
import NotionInputSelecter from "admin/component/input/NotionInputSelecter";
import { NotionTextarea } from "admin/component/input/NotionTextarea";
import styled from "@emotion/styled";
import Chip from "component/chips/Chip";
import DefaultButton from "admin/component/button/DefaultButton";
import NotionInputUser from "admin/component/input/NotionInputUser";
import {
  LatticeUserInfo,
  Position,
  SelectData,
  UserInfo,
} from "../../../../type/type";
import * as api from "../../../../../api/api";
import { DataType } from "../..";
import { ProfileUpload } from "../../component/ProfileUpload";

interface Props {
  structure: NodeModel<DataType>[];
  selectedStructure?: NodeModel<DataType>;
  userInfo?: UserInfo;
  allPosition: SelectData<Position>[];
  allHpUser: UserInfo[];
  setAllPosition: React.Dispatch<React.SetStateAction<SelectData<Position>[]>>;
  checkDuplicateEmail: (email: string, userId: string | number) => boolean;
  onUserCreate: (newUser: UserInfo) => void;
  cancelCreate: () => void;
  deleteStructureId: number;
}

interface Structures {
  struct: NodeModel<DataType>;
  isSelect?: boolean;
  depth: number;
  path: (number | string)[];
  children: Structures[];
}
const CreateUser: React.FC<Props> = (props) => {
  const structure = useRef<Structures[]>([]);
  const isCreateUser = useRef<boolean>(!props.userInfo);
  const [userInfo, setUserInfo] = useState<UserInfo>(
    props.userInfo
      ? {
          ...props.userInfo,
          keyword: props.userInfo.keyword ? props.userInfo.keyword : [""],
          careerAndEducation: props.userInfo.careerAndEducation || {
            education: [{}],
            career: [{}],
          },
        }
      : {
          isNew: true,
          isChange: false,
          isDelete: false,
          hpUserId: "",
          keyword: [""],
          careerAndEducation: {
            education: [{}],
            career: [{}],
          },
        }
  );
  const [position, setPosition] = useState<SelectData<Position>[]>([]);
  const [latticeUser, setLatticeUser] = useState<SelectData<LatticeUserInfo>[]>(
    []
  );
  const [latticeUserFilter, setLatticeUserFilter] = useState<
    SelectData<LatticeUserInfo>[]
  >([]);
  const [selectedPosition, setSelectedPosition] = useState<
    Position | undefined
  >(undefined);

  const [depth1, setDepth1] = useState<SelectData<Structures>[]>([]);
  const [selectedDepth1, setSelectedDept1] = useState<Structures | undefined>(
    undefined
  );

  const [depth2, setDepth2] = useState<SelectData<Structures>[]>([]);
  const [selectedDepth2, setSelectedDept2] = useState<Structures | undefined>(
    undefined
  );

  const [depth3, setDepth3] = useState<SelectData<Structures>[]>([]);
  const [selectedDepth3, setSelectedDept3] = useState<Structures | undefined>(
    undefined
  );

  const emptyKeywordArr = useRef<string[]>(["", "", ""]);

  const reRender = () => {
    setUserInfo({ ...userInfo });
  };

  const getStructure = (
    target: NodeModel<DataType>,
    path: (number | string)[],
    depth: number
  ): Structures[] => {
    return props.structure
      .filter((item) => item.parent === target.id)
      .map((item): Structures => {
        const _path = [...path, item.id];
        return {
          struct: item,
          depth,
          path: _path,
          children: getStructure(item, _path, depth + 1),
        };
      });
  };

  const getStructureItem = (
    findArr: Structures[],
    findId?: number | string
  ): Structures | undefined => {
    if (!findId) return undefined;

    for (const item of findArr) {
      const result: Structures | undefined =
        item.struct.id === findId
          ? item
          : getStructureItem(item.children, findId);
      if (result) return result;
    }
  };

  const onChangeUserProfile = (url: string, key: string) => {
    userInfo.profileImgUrl = url;
    userInfo.profileImgKey = key;
    if (!userInfo.isNew) userInfo.isChange = true;
    reRender();
  };

  const onChangeUserName = (name: string) => {
    if (!userInfo.isNew) userInfo.isChange = true;
    userInfo.name = name;
    reRender();
  };

  const onChangeUserPosition = (item: Position[]) => {
    if (item.length > 0) {
      userInfo.hpPosition = item[0];
      setSelectedPosition(item[0]);
      if (!userInfo.isNew) userInfo.isChange = true;
    }
  };

  const onCreatePosition = (name: string) => {
    const newPositionLength = props.allPosition.filter(
      (item) => typeof item.id === "string"
    ).length;
    const newId = `new_position_${newPositionLength}`;
    const newPosition: SelectData<Position> = {
      id: newId,
      text: name,
      data: {
        hpPositionId: newId,
        name,
      },
    };

    props.setAllPosition([...props.allPosition, newPosition]);
    userInfo.hpPosition = newPosition.data;
    if (!userInfo.isNew) userInfo.isChange = true;
  };

  const onChangeUserEmail = (item: LatticeUserInfo) => {
    const latticeUser = item;

    userInfo.email = latticeUser.email;
    userInfo.name = latticeUser.name;
    userInfo.acUserId = latticeUser.acUserId;
    userInfo.profileImgKey = latticeUser.profileImgKey;
    userInfo.profileImgUrl = latticeUser.profileImgUrl;
    userInfo.hpUserMajor = latticeUser.acUserMajor.map((user) => {
      return { hpIndustrialTech: user.acIndustrialTech };
    });
    if (!userInfo.isNew) userInfo.isChange = true;
    reRender();
  };
  const onCreateUserEmail = async (email: string) => {
    userInfo.email = email;
    if (!userInfo.isNew) userInfo.isChange = true;
    reRender();
  };
  const onChangeStructureDepth1 = (data: Structures[]) => {
    if (data.length > 0) {
      setSelectedDept1(data[0]);
      userInfo.hpStructure = data[0].struct.data?.structInfo;
      if (!userInfo.isNew) userInfo.isChange = true;
      reRender();
    }
  };
  const onChangeStructureDepth2 = (data: Structures[]) => {
    if (data.length > 0) {
      setSelectedDept2(data[0]);
      userInfo.hpStructure = data[0].struct.data?.structInfo;
      if (!userInfo.isNew) userInfo.isChange = true;
      reRender();
    }
  };
  const onChangeStructureDepth3 = (data: Structures[]) => {
    if (data.length > 0) {
      setSelectedDept3(data[0]);
      userInfo.hpStructure = data[0].struct.data?.structInfo;
      if (!userInfo.isNew) userInfo.isChange = true;
      reRender();
    }
  };

  const onDeleteProfile = () => {
    userInfo.profileImgKey = undefined;
    userInfo.profileImgUrl = undefined;
    if (!userInfo.isNew) userInfo.isChange = true;
    reRender();
  };

  const addEducation = () => {
    if (userInfo.careerAndEducation) {
      userInfo.careerAndEducation.education = [
        ...(userInfo.careerAndEducation.education || []),
        {},
      ];
      if (!userInfo.isNew) userInfo.isChange = true;
      setUserInfo({ ...userInfo });
    }
  };

  const addCareer = () => {
    if (userInfo.careerAndEducation) {
      userInfo.careerAndEducation.career = [
        ...(userInfo.careerAndEducation.career || []),
        {},
      ];
      if (!userInfo.isNew) userInfo.isChange = true;
      setUserInfo({ ...userInfo });
    }
  };
  const submitUserCreate = () => {
    const toastOption = {
      position: toast.POSITION.TOP_RIGHT,
    };

    if (!userInfo.email || userInfo.email.length === 0)
      return toast.error("이메일을 입력해주세요", toastOption);
    if (props.checkDuplicateEmail(userInfo.email, userInfo.hpUserId))
      return toast.error("중복된 이메일입니다.", toastOption);
    if (!userInfo.name || userInfo.name.length === 0)
      return toast.error("이름을 입력해주세요", toastOption);
    if (!userInfo.hpStructure)
      return toast.error("소속을 선택해주세요", toastOption);

    const newUser: UserInfo = {
      ...userInfo,
      keyword: userInfo.keyword?.filter((item) => item.length !== 0),
    };

    props.onUserCreate(newUser);
  };

  useEffect(() => {
    structure.current = props.structure
      .filter((item) => item.parent === 0)
      .filter((item) => item.id !== props.deleteStructureId)
      .map((item): Structures => {
        return {
          struct: item,
          depth: 0,
          path: [item.id],
          children: getStructure(item, [item.id], 0 + 1),
        };
      });
    setDepth1([
      ...structure.current.map((item): SelectData<Structures> => {
        return {
          id: item.struct.id,
          text: item.struct.data?.name || "",
          data: item,
        };
      }),
    ]);

    if (isCreateUser.current) {
      const userStructure = getStructureItem(
        structure.current,
        props.selectedStructure?.id
      );

      if (userStructure && userStructure.path[0]) {
        const depth1: Structures | undefined = getStructureItem(
          structure.current,
          userStructure.path[0]
        );
        if (depth1) setSelectedDept1(depth1);
        if (depth1) userInfo.hpStructure = depth1.struct.data?.structInfo;
        return;
      }
    } else if (userInfo && userInfo.hpStructure) {
      const userStructure = getStructureItem(
        structure.current,
        userInfo.hpStructure.hpStructureId
      );
      if (userStructure && userStructure.path[0]) {
        const depth1: Structures | undefined = getStructureItem(
          structure.current,
          userStructure.path[0]
        );
        if (depth1) setSelectedDept1(depth1);
        return;
      }
    }
    setSelectedDept1(undefined);
  }, [props.structure, props.userInfo]);

  useEffect(() => {
    if (selectedDepth1) {
      const selectedStructure = getStructureItem(
        structure.current,
        selectedDepth1?.struct.id
      );
      if (selectedStructure) {
        setDepth2([
          ...selectedStructure.children
            .filter((item) => item.struct.droppable)
            .map((item): SelectData<Structures> => {
              return {
                id: item.struct.id,
                text: item.struct.data?.name || "",
                data: item,
              };
            }),
        ]);
      }
    } else {
      setDepth2([]);
    }

    if (isCreateUser.current) {
      const userStructure = getStructureItem(
        structure.current,
        props.selectedStructure?.id
      );
      if (userStructure && userStructure.path[1]) {
        const depth2: Structures | undefined = getStructureItem(
          structure.current,
          userStructure.path[1]
        );
        if (depth2) setSelectedDept2(depth2);
        if (depth2) userInfo.hpStructure = depth2.struct.data?.structInfo;
        return;
      }
    } else if (userInfo && userInfo.hpStructure) {
      const userStructure = getStructureItem(
        structure.current,
        userInfo.hpStructure.hpStructureId
      );
      if (userStructure && userStructure.path[1]) {
        const depth2: Structures | undefined = getStructureItem(
          structure.current,
          userStructure.path[1]
        );
        if (depth2) setSelectedDept2(depth2);
        return;
      }
    }

    setSelectedDept2(undefined);
  }, [selectedDepth1]);

  useEffect(() => {
    if (selectedDepth2) {
      const selectedStructure = getStructureItem(
        structure.current,
        selectedDepth2?.struct.id
      );
      if (selectedStructure) {
        setDepth3([
          ...selectedStructure.children.map((item): SelectData<Structures> => {
            return {
              id: item.struct.id,
              text: item.struct.data?.name || "",
              data: item,
            };
          }),
        ]);
      }
    } else {
      setDepth3([]);
    }

    if (isCreateUser.current) {
      const userStructure = getStructureItem(
        structure.current,
        props.selectedStructure?.id
      );
      if (userStructure && userStructure.path[2]) {
        const depth3: Structures | undefined = getStructureItem(
          structure.current,
          userStructure.path[2]
        );
        if (depth3) setSelectedDept3(depth3);
        if (depth3) userInfo.hpStructure = depth3.struct.data?.structInfo;
        return;
      }
    } else if (userInfo && userInfo.hpStructure) {
      const userStructure = getStructureItem(
        structure.current,
        userInfo.hpStructure.hpStructureId
      );
      if (userStructure && userStructure.path[2]) {
        const depth3: Structures | undefined = getStructureItem(
          structure.current,
          userStructure.path[2]
        );
        if (depth3) setSelectedDept3(depth3);
        return;
      }
    }
    setSelectedDept3(undefined);
  }, [selectedDepth1, selectedDepth2]);

  const getAllLatticeUser = async () => {
    try {
      const result = await axios.get(api.getAllLatticeUser());
      if (result.status === 200) {
        const existUserEmail = props.allHpUser.map((item) => item.email);
        const latticeUserList: SelectData<LatticeUserInfo>[] = result.data.map(
          (item: any): SelectData<LatticeUserInfo> => ({
            id: item.email,
            text: item.email,
            data: { ...item },
          })
        );

        setLatticeUserFilter([
          ...latticeUserList.filter(
            (item) => !existUserEmail.includes(item.data?.email)
          ),
        ]);
        setLatticeUser(latticeUserList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllLatticeUser();
  }, []);

  return (
    <Container>
      <Header>
        <span className="font-bold-20">
          {props.userInfo ? "구성원 세부정보 수정" : "구성원 추가"}
        </span>
      </Header>
      <Contents className="scroll__invisible">
        <div className="row">
          <div className="double__item column" style={{ gap: 24 }}>
            <div className="row">
              <NotionInputUser
                label="이메일 (ID)"
                hasUnderLine
                editable={userInfo.isNew || userInfo.acUserId === undefined}
                type="single"
                placeholder="이메일을 입력하세요."
                className="single__item"
                data={latticeUserFilter}
                defaultValue={userInfo.email}
                onChange={onChangeUserEmail}
                onCreate={onCreateUserEmail}
              />
              <NotionInput
                label="이름"
                editable={userInfo.acUserId === undefined}
                hasUnderLine={userInfo.acUserId === undefined}
                textType="text"
                placeholder="이름을 입력하세요."
                className="single__item"
                defaultValue={userInfo.name}
                onChange={onChangeUserName}
              />
            </div>
            <div className="row">
              <NotionInputSelecter
                label="직급"
                hasUnderLine
                editable
                placeholder="직급을 선택하세요."
                className="single__item"
                type="single"
                data={props.allPosition}
                selectedItem={props.allPosition.filter(
                  (item) => item.id === userInfo.hpPosition?.hpPositionId
                )}
                onChange={onChangeUserPosition}
                onCreate={onCreatePosition}
              />
              <span className="single__item" />
            </div>
            {userInfo.hpUserMajor && userInfo.hpUserMajor.length > 0 && (
              <div className="row">
                <IndustryContainer>
                  <span className="font-bold-14">전문 산업 기술 분야</span>
                  <div className="industry__list">
                    {userInfo.hpUserMajor?.map((item) => (
                      <Chip isActive>{item.hpIndustrialTech.name}</Chip>
                    ))}
                  </div>
                </IndustryContainer>
              </div>
            )}
          </div>
          <div className="single__item">
            <div style={{ width: "100%", aspectRatio: "1/1" }}>
              <ProfileUpload
                profile={userInfo.profileImgUrl}
                callback={onChangeUserProfile}
                deleteImage={onDeleteProfile}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <NotionTextarea
            label="소개"
            editable
            onChange={(e) => {
              userInfo.introduction = e;
              reRender();
            }}
            defaultValue={userInfo.introduction}
          />
        </div>
        <div className="column">
          <div className="font-bold-14">
            <span>소개 키워드</span>
            {userInfo.keyword && userInfo.keyword.length < 3 && (
              <AddAnother
                className="font-bold-12"
                onClick={() => {
                  userInfo.keyword?.push("");
                  reRender();
                }}
              >
                + 추가하기
              </AddAnother>
            )}
          </div>
          <div className="row">
            {userInfo.keyword?.map((item, index) => {
              return (
                <NotionInput
                  hasUnderLine
                  editable
                  textType="text"
                  placeholder={`키워드 ${index + 1}`}
                  className="single__item"
                  onBlur={(e) => {
                    if (
                      userInfo.keyword &&
                      userInfo.keyword[index] !== undefined
                    ) {
                      userInfo.keyword[index] = e.target.value;
                      reRender();
                    }
                  }}
                  defaultValue={item}
                />
              );
            })}
            {emptyKeywordArr.current
              .filter(
                (item, index) =>
                  emptyKeywordArr.current.length -
                    (userInfo.keyword?.length || 0) >
                  index
              )
              .map((item) => {
                return <div className="single__item" />;
              })}
          </div>
        </div>
        {depth1.length > 0 && (
          <div className="row">
            <div className="single__item">
              {depth1.length > 0 && (
                <NotionInputSelecter
                  label="소속"
                  hasUnderLine
                  editable
                  placeholder="소속을 선택하세요."
                  type="single"
                  data={depth1}
                  selectedItem={depth1.filter(
                    (item) => item.id === selectedDepth1?.struct.id
                  )}
                  onChange={onChangeStructureDepth1}
                />
              )}
            </div>
            <div className="single__item">
              {depth2.length > 0 && (
                <NotionInputSelecter
                  label="실"
                  hasUnderLine
                  editable
                  placeholder="실을 선택하세요."
                  type="single"
                  data={depth2}
                  selectedItem={depth2.filter(
                    (item) => item.id === selectedDepth2?.struct.id
                  )}
                  onChange={onChangeStructureDepth2}
                />
              )}
            </div>
            <div className="single__item">
              {depth3.length > 0 && (
                <NotionInputSelecter
                  label="팀"
                  hasUnderLine
                  editable
                  placeholder="팀을 선택하세요."
                  type="single"
                  data={depth3}
                  selectedItem={depth3.filter(
                    (item) => item.id === selectedDepth3?.struct.id
                  )}
                  onChange={onChangeStructureDepth3}
                />
              )}
            </div>
          </div>
        )}
        <div className="row">
          <NotionInput
            label="트위터"
            hasUnderLine
            editable
            textType="text"
            placeholder="트위터 주소"
            className="single__item"
            onChange={(e) => {
              userInfo.twitter = e;
            }}
            defaultValue={userInfo.twitter}
          />
          <NotionInput
            label="페이스북"
            hasUnderLine
            editable
            textType="text"
            placeholder="페이스북 주소"
            className="single__item"
            onChange={(e) => {
              userInfo.facebook = e;
            }}
            defaultValue={userInfo.facebook}
          />
        </div>
        <div className="row">
          <NotionInput
            label="블로그"
            hasUnderLine
            editable
            textType="text"
            placeholder="블로그 주소"
            className="single__item"
            onChange={(e) => {
              userInfo.blog = e;
            }}
            defaultValue={userInfo.blog}
          />
          <NotionInput
            label="인스타그램"
            hasUnderLine
            editable
            textType="text"
            placeholder="인스타그램 주소"
            className="single__item"
            onChange={(e) => {
              userInfo.instagram = e;
            }}
            defaultValue={userInfo.instagram}
          />
        </div>
        <div className="row">
          <div className="column" style={{ gap: 8 }}>
            {userInfo.careerAndEducation?.education?.map((item, index) => {
              return (
                <div className="row">
                  <NotionInput
                    label={index === 0 ? "대학교(원)" : undefined}
                    hasUnderLine
                    editable
                    textType="text"
                    placeholder="대학교(원) 입력"
                    className="single__item"
                    onChange={(e) => {
                      if (
                        userInfo.careerAndEducation &&
                        userInfo.careerAndEducation.education
                      ) {
                        userInfo.careerAndEducation.education[
                          index
                        ].schoolName = e;
                      }
                    }}
                    defaultValue={item.schoolName}
                  />
                  <NotionInput
                    label={index === 0 ? "전공" : undefined}
                    hasUnderLine
                    editable
                    textType="text"
                    placeholder="전공 입력"
                    className="single__item"
                    onChange={(e) => {
                      if (
                        userInfo.careerAndEducation &&
                        userInfo.careerAndEducation.education
                      ) {
                        userInfo.careerAndEducation.education[index].major = e;
                      }
                    }}
                    defaultValue={item.major}
                  />
                </div>
              );
            })}
            <AddAnother className="font-bold-12" onClick={addEducation}>
              + 추가하기
            </AddAnother>
          </div>
        </div>
        <div className="row">
          <div className="column" style={{ gap: 8 }}>
            {userInfo.careerAndEducation?.career?.map((item, index) => {
              return (
                <div className="row">
                  <NotionInput
                    label={index === 0 ? "전직장" : undefined}
                    hasUnderLine
                    editable
                    textType="text"
                    placeholder="회사 입력"
                    className="single__item"
                    onChange={(e) => {
                      if (
                        userInfo.careerAndEducation &&
                        userInfo.careerAndEducation.career
                      ) {
                        userInfo.careerAndEducation.career[index].company = e;
                      }
                    }}
                    defaultValue={item.company}
                  />
                  <NotionInput
                    label={index === 0 ? "직무" : undefined}
                    hasUnderLine
                    editable
                    textType="text"
                    placeholder="직무 입력"
                    className="single__item"
                    onChange={(e) => {
                      if (
                        userInfo.careerAndEducation &&
                        userInfo.careerAndEducation.career
                      ) {
                        userInfo.careerAndEducation.career[index].position = e;
                      }
                    }}
                    defaultValue={item.position}
                  />
                </div>
              );
            })}
            <AddAnother className="font-bold-12" onClick={addCareer}>
              + 추가하기
            </AddAnother>
          </div>
        </div>
      </Contents>
      <ButtonContainer>
        <button className="cancel font-medium-14" onClick={props.cancelCreate}>
          취소하기
        </button>
        <DefaultButton
          type="submit"
          disabled={
            userInfo.email === undefined ||
            userInfo.email.length === 0 ||
            userInfo.name === undefined ||
            userInfo.name.length === 0 ||
            userInfo.hpPosition === undefined ||
            userInfo.hpStructure === undefined ||
            userInfo.profileImgUrl === undefined
          }
          onClick={submitUserCreate}
        >
          {props.userInfo ? "수정하기" : "추가하기"}
        </DefaultButton>
      </ButtonContainer>
    </Container>
  );
};
const Container = styled.div`
  width: 764px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
`;
const Header = styled.div`
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  border-bottom: 1px solid var(--grey_50);
  .warnning {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #ffc800;
  }
`;

const AddAnother = styled.button`
  width: fit-content;
  height: fit-content;
  padding: 10px 20px;
  border: 0;
  background-color: transparent;
  color: var(--grey-20);
`;

const Contents = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  gap: 24px;
  padding: 20px;

  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 24px;
    .column {
      display: flex;
      flex-direction: column;
    }
    .single__item {
      flex: 1;
    }
    .double__item {
      flex: 2;
    }
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--grey_20);

  width: 100%;
  height: 75px;
  min-height: 75px;
  max-height: 75px;
  padding-right: 20px;
  gap: 24px;
  button {
    padding: 0 24px;
    height: 42px;
    border: 0;
    min-width: 120px;
    cursor: pointer;
    :hover {
      filter: brightness(1.2);
    }
  }
  .submit {
    background: #174274;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    color: var(--white);
  }
  .cancel {
    background: transparent;
    color: var(--grey_50);
  }
`;

const IndustryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .industry__list {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
`;

export default React.memo(CreateUser);
