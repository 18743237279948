import React, { useEffect, useRef, useState } from "react";

import { Button } from "react-bootstrap";

import AccordionMenu from "admin/component/accordion/AccordionMenu";
import axios from "axios";
import { getRangeYear, getYear, sortingByOpenDate } from "utils/commonUtil";
import Navigation, { Pages } from "../../component/navigation";
import { EventLayout, NewsList } from "./styles";
import SearchBar from "../../component/search";
import ModalLayout from "../../component/modal/ModalLayout";
import BlueLetterCard from "./component/BlueLetterCard";
import BlueLetterDetail from "./component/BlueLetterDetail";
import * as api from "../../../api/api";

export interface BlueLetterProps {
  hpBlueLetterId?: number; // 블루레터 id
  openDate?: string; // 블루레터가 홈페이지에 노출되는 시기
  linkUrl: string; // 링크 url
  title?: string; // 제목
  thumbNail?: string; // 썸네일
  description?: string;
  isActive: boolean; // show || hide
}
interface GroupBlueLetterProps {
  year: number;
  isDefaultOpen: boolean;
  blueletterList: BlueLetterProps[];
}

const BlueLetter = () => {
  const [changeList, updateChangeList] = useState<boolean>(false);
  const [isBlueletterDetailModal, updateBlueletterDetailModal] =
    useState<boolean>(false);
  const [blueletterGroup, setBlueletterGroup] = useState<
    GroupBlueLetterProps[]
  >([]);
  const selectedBlueletterData = useRef<BlueLetterProps | undefined>(undefined);

  const onShowBlueletterDetail = (data?: BlueLetterProps) => {
    selectedBlueletterData.current = data;
    updateBlueletterDetailModal(true);
  };
  const getBlueletterList = async () => {
    try {
      const result = await axios.get(api.getBlueLetterList());
      if (result.status === 200) {
        const currentYear = getYear(new Date()) || 2022;
        const yearRange = getRangeYear(2014, currentYear, 1).reverse();
        const blueletterGroupList = yearRange.map(
          (year: number, index: number) => {
            const blueletterFilter = result.data
              .sort(sortingByOpenDate)
              .filter((item: any) => {
                const itemYear = getYear(item.openDate);
                return year === itemYear;
              });
            return {
              year,
              isDefaultOpen: index === 0,
              blueletterList: blueletterFilter,
            };
          }
        );
        const list = blueletterGroupList.filter(
          (item) => item.blueletterList.length > 0
        );
        setBlueletterGroup(list);
        updateChangeList(!changeList);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const init = async () => {
    try {
      if (await api.checkingToken()) {
        await getBlueletterList();
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    init();
  }, []);
  return (
    <Navigation activePageId={Pages[6].id}>
      <EventLayout>
        <div className="contents__header">
          <SearchBar />
          <Button
            variant="primary"
            onClick={() => onShowBlueletterDetail(undefined)}
          >
            컨텐츠 생성하기
          </Button>
        </div>
        <div className="contents__body">
          {blueletterGroup.map((item) => {
            return (
              <AccordionMenu
                header={`${item.year}년 News`}
                isDefaultOpen
                headerStyle={{ width: "704px", margin: "0 auto" }}
                notifyChangeChild={changeList}
              >
                <NewsList>
                  {item.blueletterList.map((item) => {
                    return (
                      <BlueLetterCard
                        key={item.hpBlueLetterId}
                        item={item}
                        showMenu
                        onClickItem={() => onShowBlueletterDetail(item)}
                        callback={() => {
                          getBlueletterList();
                        }}
                      />
                    );
                  })}
                </NewsList>
              </AccordionMenu>
            );
          })}
        </div>
        <ModalLayout
          isOpen={isBlueletterDetailModal}
          toggle={() => updateBlueletterDetailModal(false)}
        >
          <BlueLetterDetail
            item={selectedBlueletterData.current}
            callback={() => {
              getBlueletterList();
              updateBlueletterDetailModal(false);
            }}
          />
        </ModalLayout>
      </EventLayout>
    </Navigation>
  );
};
export default BlueLetter;
