import React from "react";
import { Link } from "react-router-dom";
import DisplayType from "utils/displayType";
import {
  Divider,
  FooterContainer,
  FooterContentContainer,
  FooterText,
} from "./styles";
import { ReactComponent as Facebook } from "../../assets/footer_sns_facebook.svg";
import { ReactComponent as Instagram } from "../../assets/footer_sns_instagram.svg";
import { ReactComponent as Blog } from "../../assets/footer_sns_naver.svg";
import { ReactComponent as Post } from "../../assets/footer_sns_post.svg";
import { ReactComponent as Youtube } from "../../assets/footer_sns_youtube.svg";

interface Props {}
const Footer: React.FC<Props> = (props) => {
  const mode = DisplayType();
  return (
    <FooterContainer>
      <FooterContentContainer className="limit__body">
        <div className="footer__content__row" style={{ paddingBottom: 40 }}>
          <FooterText className="footer__content__section">
            <span className="font-bold-14 footer__text__title">
              DAEJEON OFFICE
            </span>
            <span className="font-body4 footer__text__subtitle">
              대전광역시 유성구 엑스포로 1
            </span>
            <span className="font-body4 footer__text__subtitle">
              엑스포타워 21층
            </span>
          </FooterText>
          <FooterText className="footer__content__section">
            <span className="font-bold-14 footer__text__title">
              SEOUL OFFICE
            </span>
            <span className="font-body4 footer__text__subtitle">
              서울특별시 강남구 역삼로 172, 마루360 4층
            </span>
            <span className="font-body4 footer__text__subtitle">
              서울특별시 강남구 논현로 338, 5층
            </span>
          </FooterText>
          <FooterText className="footer__content__section">
            <span className="font-bold-14 footer__text__title">
              CONTACT MAIL
            </span>
            <div className="footer__text__row">
              <div
                className="font-body4 footer__text__subtitle"
                style={{ minWidth: 90, textAlign: "end" }}
              >
                투자 관련 문의 :{" "}
              </div>
              <a
                className="font-body4 footer__text__subtitle"
                href="mailto:apply@bluepoint.ac"
                style={{ textDecoration: "none" }}
              >
                apply@bluepoint.ac
              </a>
            </div>
            <div className="footer__text__row">
              <div
                className="font-body4 footer__text__subtitle"
                style={{ minWidth: 90, textAlign: "end" }}
              >
                기업 협업 문의 :{" "}
              </div>
              <a
                className="font-body4 footer__text__subtitle"
                href="mailto:oi-info@bluepoint.ac"
                style={{ textDecoration: "none" }}
              >
                oi-info@bluepoint.ac
              </a>
            </div>
            <div className="footer__text__row">
              <div
                className="font-body4 footer__text__subtitle"
                style={{ minWidth: 90, textAlign: "end" }}
              >
                기타 문의 :{" "}
              </div>
              <a
                className="font-body4 footer__text__subtitle"
                href="mailto:contact@bluepoint.ac"
                style={{ textDecoration: "none" }}
              >
                contact@bluepoint.ac
              </a>
            </div>
          </FooterText>
          <div
            className="footer__content__section"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: mode === "Mobile" ? "flex-start" : "flex-end",
              gap: mode === "Mobile" ? 16 : 8,
            }}
          >
            <a
              className="sns__link"
              href="https://www.youtube.com/channel/UCLDZNWgT0dfijTGW8v7NVcQ"
              target={"_blank"}
              rel="noreferrer"
            >
              <Youtube />
            </a>
            <a
              className="sns__link"
              href={"https://www.facebook.com/BluepointPartners/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Facebook />
            </a>
            <a
              className="sns__link"
              href={"https://blog.naver.com/bluepoint_ac"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Blog />
            </a>
            <a
              className="sns__link"
              href={"https://www.instagram.com/bluepoint.ac/"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Instagram />
            </a>
            <a
              className="sns__link"
              href={"https://post.naver.com/my.naver?memberNo=45630014"}
              target={"_blank"}
              rel="noreferrer"
            >
              <Post />
            </a>
          </div>
        </div>
        <Divider />
        <div
          className="footer__content__row"
          style={{ justifyContent: "space-between", marginTop: 16 }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 16,
            }}
          >
            <Link
              className="font-bold-12"
              to={"/privacy-policy"}
              style={{ textDecoration: "none", color: "var(--grey-30)" }}
            >
              개인정보 처리방침
            </Link>
            {/* <Link
              className="font-bold-12"
              to={"/"}
              style={{ textDecoration: "none", color: "var(--grey-30)" }}
            >
              기업윤리규범
            </Link> */}
          </div>
          <span className="font-regular-14" style={{ color: "var(--grey-50)" }}>
            (c) Copyright BluepointPartners All Rights Reserved.
          </span>
        </div>
      </FooterContentContainer>
    </FooterContainer>
  );
};
export default Footer;
