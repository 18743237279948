import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";
import { ReactComponent as Logo } from "../../assets/logo_start_point.svg";
import { ReactComponent as Email } from "../../assets/email__icon.svg";

const StartPoint = () => {
  const mode = DisplayType();

  useLayoutEffect(() => {
    updateTitle("시작점");
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <Logo className="program__logo" />
        <span
          className={`program__title ${
            mode === "Mobile" ? "font-bold-24" : "font-title"
          }`}
        >
          시작점
        </span>
        <p
          className={`program__description ${
            mode === "Mobile" ? "font-body3" : "font-body2"
          }`}
        >
          야심찬 창업가들을 위한 다양한{mode === "Mobile" && <br />} 네트워킹과
          코워킹,
          <br /> 강연회와 이벤트가 이루어지는 공간입니다.
        </p>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        <ProgramDescription>
          <div
            className={`program__description__title ${
              mode === "Mobile" ? "font-sub-title2" : "font-title"
            }`}
          >
            창업을 시작하는 모든 사람들의 커뮤니티
          </div>
          <p className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}>
            스타트업 생태계에서 네트워킹의 힘은 실로 대단합니다. 스타트업을
            성장시키려면 창업가 자신의 비전을 믿어주고, 끌어주고, 힘을 실어주는
            사람들이 꼭 필요하기 때문입니다. 시작점은 블루포인트의 자회사로서,
            창업가들이 만나 교감할 수 있는 네트워킹 접점과 코워킹 공간을
            제공합니다.
            <br />
            <br /> 시작점 멤버가 되면 회계/개발/디자인/특허 등 분야별 전문가의
            상담과 교육 콘텐츠, 창업 선배들의 토크콘서트 등 다양한 성장 프로그램
            또한 만날 수 있습니다. 대부분의 프로그램은 온/오프라인으로 진행되며,
            대전 등 비수도권 지역의 창업 커뮤니티를 활성화하여 새로운 만남과
            기회의 범위를 확장합니다.
          </p>
        </ProgramDescription>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            프로그램 과정
          </div>
          <div className={`program__process__row`}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              설명
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              예비창업가 또는 초기창업가 들이 모여 서로 배우고 영감을 얻을 수
              있는 공간과 커뮤니티
            </span>
          </div>
          <div className={`program__process__row`} style={{ marginTop: 8 }}>
            <span
              className={`${
                mode === "Mobile" ? "font-bold-16" : "font-bold-18"
              }`}
            >
              대상
            </span>
            <span
              className={`${mode === "Mobile" ? "font-body3" : "font-body2"}`}
            >
              예비창업자 또는 초기 스타트업 멤버라면 누구나
            </span>
          </div>
          <div className="program__process__list">
            <ProgramProcessItem>
              <span
                className={`item__title ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                오픈형 네트워킹 및 팀 빌딩 프로젝트
              </span>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                별도 공고를 통해 제한된 T/O 하에 지원 가능
              </span>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <span
                className={`item__title ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                멤버십 only 프로그램과 공간 사용
              </span>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                <a
                  href="https://startingpoint.co.kr"
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  시작점 홈페이지
                </a>{" "}
                가입 후 멤버십 이용권 구매
              </span>
            </ProgramProcessItem>
            <ProgramProcessItem>
              <span
                className={`item__descrition ${
                  mode === "Mobile" ? "font-body3" : "font-body2"
                }`}
              >
                시작점에서 벌어지는 모든 일들은{" "}
                <a
                  href="https://www.instagram.com/startingpoint_official/"
                  target={"_blank"}
                  rel="noreferrer"
                  style={{ textDecoration: "none" }}
                >
                  시작점 인스타
                </a>
                를 통해 확인 가능
              </span>
            </ProgramProcessItem>
          </div>
        </ProgramProcess>
        <ProgramProcess>
          <div
            className={`program__process__title ${
              mode === "Mobile" ? "font-bold-22" : "font-sub-title"
            }`}
          >
            문의처
          </div>
          <a
            href="mailto:contact@startingpoint.co.kr"
            style={{ textDecoration: "none" }}
          >
            <EmailContainer>
              <Email className="email__icon" />
              <span
                className={`email__text ${
                  mode === "Mobile" ? "font-regular-16" : "font-regulra-22"
                }`}
              >
                contact@startingpoint.co.kr
              </span>
            </EmailContainer>
          </a>
        </ProgramProcess>
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-02);
  padding-top: 80px;
  padding-bottom: 80px;

  .program__logo {
    width: 240px;
    min-width: 240px;
    max-width: 240px;
    height: 240px;
    min-width: 240px;
    max-width: 240px;
  }
  .program__title {
    color: var(--black);
    margin-top: 24px;
    margin-bottom: 16px;
  }
  .program__description {
    color: var(--grey-40);
    text-align: center;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 60px;
    .program__logo {
      width: 117px;
      min-width: 117px;
      max-width: 117px;
      height: 117px;
      min-width: 117px;
      max-width: 117px;
    }
    .program__title {
      color: var(--black);
      margin-top: 24px;
      margin-bottom: 8px;
    }
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  margin-bottom: 160px;
`;

const ProgramDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__description__title {
    color: var(--primary);
    margin-bottom: 24px;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__description__title {
      margin-bottom: 16px;
    }
  }
`;

const ProgramProcess = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  margin-top: 100px;

  .program__process__title {
    color: var(--black);
    margin-bottom: 24px;
  }

  .program__process__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
  }

  .program__process__list {
    width: 100%;
    border-top: 1px solid var(--grey-00);
    display: flex;
    flex-direction: column;
    margin-top: 24px;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
    .program__process__title {
      margin-bottom: 16px;
    }
    .program__process__row {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
`;

const ProgramProcessItem = styled.div`
  width: 100%;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--grey-00);

  .item__title {
    color: var(--primary);
  }
  .item__descrition {
    color: var(--grey-60);
  }
  @media only screen and (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const EmailContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  .email__icon {
    width: 32px;
    height: 32px;
  }
  .email__text {
    color: var(--primary);
  }
`;
export default StartPoint;
