import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import Navigations from "component/navigation/Navigations";
import MobileNavigations from "component/navigation/MobileNavigations";
import DisplayType from "utils/displayType";
import { NavigationMenu } from "admin/type/type";
import NavigationsNew from "component/navigation/NavigationsNew";
import styled from "@emotion/styled";
import ReactGA from "react-ga4";
import { Logo } from "./styles";

import { ReactComponent as NewLogo } from "../../assets/new_logo.svg";
import { ReactComponent as NewLogoWhite } from "../../assets/new_logo_white.svg";
import { ReactComponent as MNewLogo } from "../../assets/m_new_logo.svg";
import { ReactComponent as MNewLogoWhite } from "../../assets/m_new_logo_white.svg";
import { ReactComponent as HamburgerBlack } from "../../assets/m_nav_menu_black.svg";
import { ReactComponent as HamburgerWhite } from "../../assets/m_nav_menu.svg";
import { ReactComponent as Close } from "../../assets/m_nav_close.svg";

interface HeaderTheme {
  selected?: NavigationMenu;
}

interface HeaderStateProp {
  id: NavigationMenu;
  isFillHeader: boolean;
}

const getIsAdmin = () => {
  if (process.env.REACT_APP_MAINTAINANCE === "true") {
    return true;
  }
  return window.location.pathname.includes("dash");
};
const getPathMenu = () => {
  const pathname = window.location.pathname.split("/");
  switch (pathname[pathname.length - 1]) {
    case "main":
      return NavigationMenu.MAIN;
    case "about":
      return NavigationMenu.COMPANY;
    case "program":
      return NavigationMenu.PROGRAM;
    case "start-point":
      return NavigationMenu.STARTINGPOINT;
    case "alumni":
      return NavigationMenu.ALUMNI;
    case "bluelabs":
      return NavigationMenu.BLUELABS;
    case "blueschool":
      return NavigationMenu.BLUESCHOOL;
    case "open-innovation":
      return NavigationMenu.OPENINNOVATION;
    case "team":
      return NavigationMenu.TEAM;
    case "portfolio":
      return NavigationMenu.PORTFOLIO;
    case "insight":
      return NavigationMenu.INSIGHT;
    case "events":
      return NavigationMenu.EVENTS;
    case "contents":
      return NavigationMenu.CONTENTS;
    case "news":
      return NavigationMenu.NEWS;
    case "blueletter":
      return NavigationMenu.BLUELETTER;
    case "apply":
      return NavigationMenu.APPLY;
    case "partnership":
      return NavigationMenu.PARTNERSHIP;
    case "faq":
      return NavigationMenu.FAQ;
    case "privacy-policy":
      return NavigationMenu.PRIVACY_POLICY;
    case "disclosure-info":
      return NavigationMenu.DISCLOSURE_INFOMATION;
    case "stock-info":
      return NavigationMenu.STOCK_INFOMATIOIN;
    case "ir-archive":
      return NavigationMenu.IR_DATA;
    case "announcement":
      return NavigationMenu.ANNOUNCEMENT;

    default: {
      if (pathname[pathname.length - 2] === "portfolio")
        return NavigationMenu.PORTFOLIODETAIL;
      if (pathname[pathname.length - 2] === "staff")
        return NavigationMenu.TEAMDETAIL;
      if (pathname[pathname.length - 2] === "events")
        return NavigationMenu.EVENTSDETAIL;
      if (pathname[pathname.length - 2] === "contents")
        return NavigationMenu.CONTENTSDETAIL;
      if (pathname[pathname.length - 2] === "announcement")
        return NavigationMenu.ANNOUNCEMENTDETAIL;
      return NavigationMenu.MAIN;
    }
  }
};

const HeaderNavigationNew: React.FC<HeaderTheme> = (props) => {
  const mode = DisplayType();

  const [isAdminPage, updateAdminPage] = useState<boolean>(getIsAdmin());

  const headerStateProps: HeaderStateProp[] = [
    {
      id: NavigationMenu.MAIN,
      isFillHeader: mode === "Mobile",
    },
    {
      id: NavigationMenu.PRIVACY_POLICY,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.ABOUT,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.PROGRAM,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.STARTINGPOINT,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.ALUMNI,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.BLUELABS,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.BLUESCHOOL,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.OPENINNOVATION,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.TEAM,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.TEAMDETAIL,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.PORTFOLIO,
      isFillHeader: mode !== "Mobile",
    },
    {
      id: NavigationMenu.PORTFOLIODETAIL,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.INSIGHT,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.EVENTS,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.EVENTSDETAIL,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.CONTENTS,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.CONTENTSDETAIL,
      isFillHeader: true,
    },
    {
      id: NavigationMenu.NEWS,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.OPENINNOVATION,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.APPLY,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.PARTNERSHIP,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.FAQ,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.MAIN,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.IR,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.DISCLOSURE_INFOMATION,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.ANNOUNCEMENT,
      isFillHeader: false,
    },
    {
      id: NavigationMenu.ANNOUNCEMENTDETAIL,
      isFillHeader: true,
    },
  ];

  const [selected, updateSelected] = useState<NavigationMenu>(getPathMenu());
  const selectedRef = useRef<NavigationMenu>(getPathMenu());
  const [isFillNavBackground, updateFillNavBackground] = useState<boolean>(
    headerStateProps.find((item) => item.id === getPathMenu())?.isFillHeader ||
      false
  );
  // const [isScrollTop, updateScrollTop] = useState<boolean>(true);
  const [isMenuOpen, updateMenuOpen] = useState<boolean>(false);
  const [subMenuOpen, updateSubMenuOpen] = useState<boolean>(false);
  const isSubMenuOpen = useRef<boolean>(false);
  const scrollHandler = (e?: any) => {
    const isTop = mode === "Mobile" ? 0 : 80;
    const menuOpen = mode === "Mobile" ? isMenuOpen : isSubMenuOpen.current;

    updateFillNavBackground(
      headerStateProps.find((item) => item.id === getPathMenu())
        ?.isFillHeader ||
        menuOpen ||
        window.scrollY > isTop
    );
  };

  const refreshPage = (selectedNavigation: NavigationMenu) => {
    if (getPathMenu() === selectedNavigation) {
      window.location.reload();
    }
    window.scrollTo({ top: 0, behavior: "auto" });
    selectedRef.current = selectedNavigation;
    updateSelected(selectedNavigation);
    updateMenuOpen(false);
  };

  useEffect(() => {
    updateSelected(getPathMenu());
    updateAdminPage(getIsAdmin());

    if (
      process.env.NODE_ENV === "production" &&
      process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
    ) {
      ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    scrollHandler();
  }, [subMenuOpen, selected]);

  useEffect(() => {
    window.addEventListener("scroll", scrollHandler);
    window.addEventListener("click", scrollHandler);
    return () => {
      window.removeEventListener("scroll", scrollHandler);
      window.removeEventListener("click", scrollHandler);
    };
  }, []);
  return (
    <HeaderNavigationContainer
      className={`${isAdminPage && "admin__page"}`}
      isFillNavBackground={isFillNavBackground}
      isOpenMenu={isMenuOpen}
      onMouseOver={() => {
        updateSubMenuOpen(true);
        isSubMenuOpen.current = true;
      }}
      onMouseLeave={() => {
        updateSubMenuOpen(false);
        isSubMenuOpen.current = false;
      }}
    >
      <div className="header__navigation__container">
        <div className="main__navigation__container">
          <Logo type="button">
            <Link to="/" onClick={() => refreshPage(NavigationMenu.MAIN)}>
              {DisplayType() === "Mobile" ? (
                <div>
                  {!isMenuOpen && !isFillNavBackground ? (
                    <MNewLogoWhite />
                  ) : (
                    <MNewLogo />
                  )}
                </div>
              ) : (
                <div>
                  {!isFillNavBackground ? <NewLogoWhite /> : <NewLogo />}
                </div>
              )}
            </Link>
          </Logo>
          {mode !== "Mobile" && (
            <NavigationsNew
              selected={selected}
              isSubMenuOpen={subMenuOpen}
              isFillNavBackground={isFillNavBackground}
              refreshPage={refreshPage}
            />
          )}
          {mode === "Mobile" && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                updateMenuOpen(!isMenuOpen);
              }}
            >
              {isMenuOpen ? (
                <Close width={32} height={32} />
              ) : (
                <div>
                  {!isFillNavBackground ? (
                    <HamburgerWhite />
                  ) : (
                    <HamburgerBlack />
                  )}
                </div>
              )}
            </span>
          )}
        </div>
      </div>
      {mode !== "Mobile" && (
        <div
          className={`header__navigation__container ${
            subMenuOpen ? "show" : "hide"
          }`}
        />
      )}
      <MobileNavigations
        selected={selected}
        isMenuOpen={isMenuOpen}
        refreshPage={refreshPage}
      />
    </HeaderNavigationContainer>
  );
};

export const HeaderNavigationContainer = styled.div<{
  isFillNavBackground?: boolean;
  isOpenMenu?: boolean;
}>`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isFillNavBackground ? "var(--white)" : "transparent"};
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 10;

  &.admin__page {
    display: none;
  }

  .header__navigation__container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid
      ${(props) =>
        props.isFillNavBackground ? "var(--grey-00)" : "transparent"};
  }

  .hide {
    height: 0px;
    transition: height 0.3s ease;
  }
  .show {
    height: 220px;
    transition: height 0.3s ease;
  }

  .main__navigation__container {
    position: relative;
    width: 100%;
    max-width: 1136px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    padding: 0 20px;
    min-height: 60px;
    height: fit-content;
    background-color: ${(props) => {
      if (props.isOpenMenu) return "var(--white)";
      if (props.isFillNavBackground) return "var(--white)";
      return "transparent";
    }};

    .header__navigation__container {
      border-bottom: 1px solid transparent;
    }
  }
`;
export default HeaderNavigationNew;
