import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import DocumentEditor from "admin/component/editor/DocumentEditor";
import { NotionInput } from "admin/component/input/NotionInput";
import axios from "axios";
import UploadImg from "admin/component/upload-img/UploadImg";
import DefaultButton from "admin/component/button/DefaultButton";
import { getYMD } from "utils/commonUtil";
import Chips from "component/chips";
import Chip from "component/chips/Chip";
import * as api from "../../../../api/api";
import { ContentCategory, imgCategory } from "../../../type/type";
import { ContentProps } from "..";

interface Props {
  item?: ContentProps;
  callback?: () => void;
  close?: () => void;
}

const ContentDetail: React.FC<Props> = (props) => {
  const categoryList = [
    ContentCategory.REPORT,
    ContentCategory.COLUMN,
    ContentCategory.INTERVIEW,
  ];
  const hpContentId = useRef<number | undefined>(undefined);
  const isActive = useRef<boolean>(true);
  const content = useRef<string>("");
  const description = useRef<string>("");

  const [title, updateTitle] = useState<string | undefined>(undefined);
  const [thumbNailImgKey, updateThumbNailImgKey] = useState<string | undefined>(
    undefined
  );
  const [thumbNailImgUrl, updateThumbNailImgUrl] = useState<string | undefined>(
    undefined
  );
  const [openDate, updateOpenDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [category, updateCategory] = useState<ContentCategory | undefined>(
    undefined
  );

  const getContentDetail = async () => {
    try {
      if (props.item?.hpContentId === undefined) return;

      const result = await axios.get(
        api.getContentDetail(props.item.hpContentId)
      );
      if (result.status === 200) {
        const data = result.data;
        isActive.current = data.isActive;
        hpContentId.current = data.hpContentId;
        content.current = data.contents;
        description.current = data.description;
        updateCategory(data.category);
        updateTitle(data.title);
        updateThumbNailImgKey(data.thumbNailImgKey);
        updateThumbNailImgUrl(data.thumbNailImgUrl);
        updateOpenDate(getYMD(data.openDate));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateContent = async () => {
    try {
      const param = {
        hpContentId: hpContentId.current,
        thumbNailImgKey,
        thumbNailImgUrl,
        openDate,
        title,
        contents: content.current,
        description: description.current,
        category,
        isActive: isActive.current,
      };

      const result = await axios.put(api.updateContent(), param);
      if (result.status === 200 && props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createContent = async () => {
    try {
      const param = {
        thumbNailImgKey,
        thumbNailImgUrl,
        openDate,
        title,
        contents: content.current,
        description: description.current,
        category,
        isActive: isActive.current,
      };

      const result = await axios.post(api.createContent(), param);

      if (result.status === 201 && props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (props.item !== undefined) {
      getContentDetail();
    }
  }, [props.item]);
  return (
    <Container>
      <div className="content__detail__header font-bold-18">
        <span />
        {props.item ? "이벤트 수정" : "이벤트 추가"}
        <DefaultButton
          disabled={
            thumbNailImgKey === undefined ||
            thumbNailImgUrl === undefined ||
            openDate === undefined ||
            title === undefined ||
            category === undefined ||
            content.current === undefined
          }
          onClick={() => {
            if (props.item) {
              updateContent();
            } else {
              createContent();
            }
          }}
        >
          {props.item ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="content__detail__body">
        <div className="content__detail__body__header">
          <UploadImg
            editable
            category={imgCategory.EVENT}
            imgUrl={thumbNailImgUrl}
            onChangeFile={(url, key) => {
              updateThumbNailImgKey(key);
              updateThumbNailImgUrl(url);
            }}
            width={350}
            height={266}
          />
          <div className="body__header__content">
            <div className="body__header__content__item">
              <NotionInput
                label="제목"
                hasUnderLine
                editable
                textType="text"
                defaultValue={title}
                onChange={(e) => {
                  updateTitle(e);
                }}
              />
            </div>
            <div className="row">
              <NotionInput
                className="flex1"
                label="게시일"
                hasUnderLine
                editable
                textType="date"
                max="9999-12-31"
                placeholder="연도. 월. 일"
                defaultValue={openDate}
                onChange={(e) => {
                  updateOpenDate(e);
                }}
              />
            </div>
            <div className="row">
              {categoryList.map((item) => {
                return (
                  <Chip
                    key={item}
                    isClickable
                    isActive={item === category}
                    onClickChip={() => {
                      updateCategory(item);
                    }}
                  >
                    {item}
                  </Chip>
                );
              })}
            </div>
          </div>
        </div>
        <div className="content__container">
          <DocumentEditor
            isEditable
            initialValue={content.current}
            category={imgCategory.EVENT}
            onEditorChange={(html, text) => {
              content.current = html;
              description.current = text;
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 1023px;
  height: 90vh;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .content__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .content__detail__body {
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 24px;
    gap: 10px;

    .content__detail__body__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;

      .thumbnail {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        aspect-ratio: 3 / 4;
        border-radius: 8px;
        background-color: grey;
      }
      .body__header__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .body__header__content__item {
          flex: 1;
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }

    .content__container {
      width: calc(100%);
      max-width: 816px;
      flex-grow: 1;

      margin: 0 auto;
    }
  }
  .row {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 10px;
    .flex1 {
      flex: 1;
    }
  }
`;
export default ContentDetail;
