import React, { useState } from "react";
import { SearchBarLayout } from "./styles";
import { ReactComponent as SearchIcon } from "../../../assets/search.svg";

interface Props {}

const SearchBar: React.FC<Props> = (props) => {
  return (
    <SearchBarLayout>
      <input />
      <SearchIcon className="search__icon" />
    </SearchBarLayout>
  );
};
export default SearchBar;
