import React, { useRef, useEffect, useState } from "react";

import DisplayType from "utils/displayType";
import { PortfolioProps, Structure, UserInfo } from "admin/type/type";
import styled from "@emotion/styled";
import PortfolioCard from "admin/router/portfolio/component/PortfolioCard";
import { Pagination } from "@mui/material";
import DefaultButton from "admin/component/button/DefaultButton";
import ModalLayout from "admin/component/modal/ModalLayout";
import PortfolioModal from "router/portfolio/organism/PortfolioModal";

interface Props {
  portfolioList: PortfolioProps[];
}

const DetailInvests: React.FC<Props> = (props) => {
  const mode = DisplayType();

  const selectedPortfolioId = useRef<number>(-1);
  const [portfolioModalOpen, updatePortfolioModalOpen] =
    useState<boolean>(false);

  const countPerPage = useRef<number>(mode === "Mobile" ? 4 : 6);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const [totalPage, updateTotalPage] = useState<number>(
    Math.ceil(props.portfolioList.length / countPerPage.current)
  );

  const onClickPortfolio = (id: number) => {
    selectedPortfolioId.current = id;
    updatePortfolioModalOpen(true);
  };

  const getStartIndex = () => {
    return mode === "Mobile" ? 0 : (currentPage - 1) * countPerPage.current;
  };

  useEffect(() => {}, []);

  return (
    <Container>
      <LabelContainer>
        <span className="font-sub-title2-en">Investments</span>
      </LabelContainer>
      <PortfolioList>
        {props.portfolioList
          .slice(getStartIndex(), currentPage * countPerPage.current)
          .map((item) => {
            return (
              <PortfolioCard
                key={item.portfolioId}
                portfolio={item}
                onClick={() => {
                  onClickPortfolio(item.portfolioId);
                }}
              />
            );
          })}
      </PortfolioList>
      {mode !== "Mobile" && totalPage > 0 && (
        <div className="center" style={{ marginTop: 16 }}>
          <Pagination
            count={totalPage}
            shape="rounded"
            page={currentPage}
            onChange={(event, page) => {
              updateCurrentPage(page);
            }}
          />
        </div>
      )}
      {mode === "Mobile" && totalPage > 0 && currentPage < totalPage && (
        <div className="center" style={{ marginTop: 40 }}>
          <DefaultButton
            className="font-button2"
            type="outline"
            onClick={() => {
              updateCurrentPage(currentPage + 1);
            }}
          >
            포트폴리오사 더보기
          </DefaultButton>
        </div>
      )}

      <ModalLayout isOpen={portfolioModalOpen} borderRadius={0}>
        <PortfolioModal
          portfolioId={selectedPortfolioId.current}
          close={() => {
            selectedPortfolioId.current = -1;
            updatePortfolioModalOpen(false);
          }}
        />
      </ModalLayout>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;

  .more__btn {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 64px;
  }

  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
`;

const PortfolioList = styled.ul`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  padding: 0;
  margin-top: 24px;

  @media only screen and (max-width: 768px) {
    column-gap: 7px;
    row-gap: 24px;
    margin-top: 16px;
  }
`;
export default React.memo(DetailInvests);
