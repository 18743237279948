import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import DocumentEditor from "admin/component/editor/DocumentEditor";
import { NotionInput } from "admin/component/input/NotionInput";
import axios from "axios";
import UploadImg from "admin/component/upload-img/UploadImg";
import DefaultButton from "admin/component/button/DefaultButton";
import { getYMD } from "utils/commonUtil";
import { EventCardProps, EventProps } from "..";
import * as api from "../../../../api/api";
import { imgCategory } from "../../../type/type";

interface Props {
  item?: EventProps;
  callback?: () => void;
  close?: () => void;
}

const EventDetail: React.FC<Props> = (props) => {
  const hpEventId = useRef<number | undefined>(undefined);
  const [title, updateTitle] = useState<string | undefined>(undefined);
  const [thumbNailImgKey, updateThumbNailImgKey] = useState<string | undefined>(
    undefined
  );
  const [thumbNailImgUrl, updateThumbNailImgUrl] = useState<string | undefined>(
    undefined
  );
  const [openDate, updateOpenDate] = useState<string | undefined>(undefined);
  const [eventStartDate, updateEventStartDate] = useState<string | undefined>(
    undefined
  );
  const [eventEndDate, updateEventEndDate] = useState<string | undefined>(
    undefined
  );
  const [eventDate, updateEventDate] = useState<string | undefined>(undefined);
  const [place, updatePlace] = useState<string | undefined>(undefined);
  const [linkUrl, updateLinkUrl] = useState<string | undefined>(undefined);
  const isActive = useRef<boolean>(true);
  const content = useRef<string>("");

  const getEventDetail = async () => {
    try {
      if (props.item?.hpEventId === undefined) return;

      const result = await axios.get(api.getEventDetail(props.item.hpEventId));
      if (result.status === 200) {
        const data = result.data;
        isActive.current = data.isActive;
        hpEventId.current = data.hpEventId;
        content.current = data.content;
        updateTitle(data.title);
        updateThumbNailImgKey(data.thumbNailImgKey);
        updateThumbNailImgUrl(data.thumbNailImgUrl);
        updateOpenDate(getYMD(data.openDate));
        updateEventStartDate(getYMD(data.eventStartDate));
        updateEventEndDate(getYMD(data.eventEndDate));
        updateEventDate(getYMD(data.eventDate));
        updatePlace(data.place);
        updateLinkUrl(data.linkUrl);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const upsertEvent = async () => {
    try {
      const param = {
        thumbNailImgKey,
        thumbNailImgUrl,
        openDate,
        eventStartDate,
        eventEndDate,
        eventDate,
        title,
        place,
        linkUrl,
        content: content.current,
        isActive: isActive.current,
      };
      if (hpEventId.current !== undefined) {
        Object.assign(param, { hpEventId: hpEventId.current });
      }
      const result =
        hpEventId.current === undefined
          ? await axios.post(api.createEvent(), param)
          : await axios.put(api.updateEvent(), param);

      if ((result.status === 201 || result.status === 200) && props.callback) {
        props.callback();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (props.item !== undefined) {
      getEventDetail();
    }
  }, [props.item]);
  return (
    <Container>
      <div className="event__detail__header font-bold-18">
        <span />
        {props.item ? "이벤트 수정" : "이벤트 추가"}
        <DefaultButton
          disabled={
            thumbNailImgKey === undefined ||
            thumbNailImgUrl === undefined ||
            openDate === undefined ||
            eventStartDate === undefined ||
            eventEndDate === undefined ||
            title === undefined ||
            place === undefined ||
            linkUrl === undefined ||
            content === undefined
          }
          onClick={upsertEvent}
        >
          {props.item ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="event__detail__body">
        <div className="event__detail__body__header">
          <UploadImg
            editable
            category={imgCategory.EVENT}
            imgUrl={thumbNailImgUrl}
            onChangeFile={(url, key) => {
              updateThumbNailImgKey(key);
              updateThumbNailImgUrl(url);
            }}
            width={200}
            height={266}
          />
          <div className="body__header__content">
            <div className="body__header__content__item">
              <NotionInput
                label="제목"
                hasUnderLine
                editable
                textType="text"
                placeholder="제목을 입력해주세요."
                defaultValue={title}
                onChange={(e) => {
                  updateTitle(e);
                }}
              />
            </div>
            <div className="row">
              <NotionInput
                className="flex1"
                label="게시일"
                hasUnderLine
                editable
                textType="date"
                max="9999-12-31"
                placeholder="연도. 월. 일"
                defaultValue={openDate}
                onChange={(e) => {
                  updateOpenDate(e);
                }}
              />
              <NotionInput
                className="flex1"
                label="이벤트 날짜"
                hasUnderLine
                editable
                textType="date"
                max="9999-12-31"
                placeholder="연도. 월. 일"
                defaultValue={eventDate}
                onChange={(e) => {
                  updateEventDate(e);
                }}
              />
            </div>
            <div className="row">
              <NotionInput
                className="flex1"
                label="시작 날짜"
                hasUnderLine
                editable
                textType="date"
                max="9999-12-31"
                placeholder="연도. 월. 일"
                defaultValue={eventStartDate}
                onChange={(e) => {
                  updateEventStartDate(e);
                  updateEventEndDate(e);
                }}
              />
              <NotionInput
                className="flex1"
                label="종료 날짜"
                hasUnderLine
                editable
                textType="date"
                min={eventStartDate}
                max="9999-12-31"
                placeholder="연도. 월. 일"
                defaultValue={eventEndDate}
                onChange={(e) => {
                  updateEventEndDate(e);
                }}
              />
            </div>
            <div className="row">
              <NotionInput
                className="flex1"
                label="장소"
                hasUnderLine
                editable
                textType="text"
                placeholder="장소를 입력해주세요."
                defaultValue={place}
                onChange={(e) => {
                  updatePlace(e);
                }}
              />
              <NotionInput
                className="flex1"
                label="링크"
                hasUnderLine
                editable
                textType="text"
                placeholder="https:// 를 반드시 입력해주세요."
                defaultValue={linkUrl}
                onChange={(e) => {
                  updateLinkUrl(e);
                }}
              />
            </div>
          </div>
        </div>
        <div className="event__content__container">
          <DocumentEditor
            isEditable
            initialValue={content.current}
            category={imgCategory.EVENT}
            onEditorChange={(editor) => {
              content.current = editor;
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 1023px;
  height: 90vh;

  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .event__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .event__detail__body {
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 24px;
    gap: 10px;

    .event__detail__body__header {
      width: 100%;
      display: flex;
      flex-direction: row;
      gap: 16px;

      .thumbnail {
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        aspect-ratio: 3 / 4;
        border-radius: 8px;
        background-color: grey;
      }
      .body__header__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        gap: 10px;
        .body__header__content__item {
          flex: 1;
          display: flex;
          flex-direction: row;
          gap: 10px;
          .body__header__content__item__subject {
            width: 80px;
          }
          .body__header__content__item__input {
            flex-grow: 1;
          }
        }
      }
    }

    .event__content__container {
      width: calc(100%);
      flex-grow: 1;
      padding: 0 auto;
    }
  }
  .row {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 10px;
    .flex1 {
      flex: 1;
    }
  }
`;

const DatePicker = styled.input`
  width: fit-content;
  border: 0;
  :focus {
    outline: none;
  }
`;

export default EventDetail;
