import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NewsProps } from "admin/router/news";
import DisplayType from "utils/displayType";
import { getYMD } from "utils/commonUtil";
import { ReactComponent as EmptyNews } from "../../assets/empty_news.svg";

interface Props {
  news: NewsProps;
  isViewer?: boolean;
  isPage?: boolean;
  onClick?: () => void;
}

const News: React.FC<Props> = (props) => {
  const { isViewer = false } = props;
  const [loading, updateLoading] = useState<boolean>(false);
  const mode = DisplayType();
  useEffect(() => {}, []);

  return (
    <Container
      href={props.news.linkUrl}
      isPage={props.isPage}
      target={"_blank"}
      onClick={props.onClick}
    >
      <div className="thumbnail__container">
        {!loading && (
          <div className="empty__thumbnail">
            <EmptyNews />
          </div>
        )}
        <img
          className="thumbNail"
          style={{ opacity: loading ? 1 : 0 }}
          src={props.news.thumbNail}
          onLoad={(event) => {
            updateLoading(true);
          }}
          onError={(err) => {
            console.log(err);
          }}
          alt="logo"
        />
      </div>

      <InfoContainer>
        {props.news.hpPortfolio?.companyName && (
          <span
            className={`news__portfolio ${
              mode === "Mobile"
                ? "font-small-text"
                : props.isPage
                ? "font-bold-14"
                : "font-sub-title3"
            }`}
          >
            {props.news.hpPortfolio?.companyName}
          </span>
        )}
        <p
          className={`news__title ${
            mode === "Mobile"
              ? "font-bold-16"
              : props.isPage
              ? "font-sub-title4"
              : "font-sub-title2"
          }`}
        >
          {props.news.title}
        </p>
        <p
          className={`news__description ${
            mode === "Mobile"
              ? "font-body4"
              : props.isPage
              ? "font-body4"
              : "font-body2"
          }`}
        >
          {props.news.description}
        </p>
        <span
          className={`news__date ${
            mode === "Mobile"
              ? "font-medium-12"
              : props.isPage
              ? "font-small-text"
              : "font-body4"
          }`}
        >
          {getYMD(props.news.openDate)}
        </span>
      </InfoContainer>
    </Container>
  );
};

const Container = styled.a<{ isPage?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;

  background: #ffffff;
  text-decoration: none;

  transition: all 0.1s ease;
  cursor: pointer;
  border-bottom: 1px solid var(--grey-00);
  padding-bottom: 24px;

  .thumbnail__container {
    position: relative;
    width: ${(props) => (props.isPage ? "180px" : "240px")};
    min-width: ${(props) => (props.isPage ? "180px" : "240px")};
    max-width: ${(props) => (props.isPage ? "180px" : "240px")};
    height: ${(props) => (props.isPage ? "116px" : "164px")};
    min-height: ${(props) => (props.isPage ? "116px" : "164px")};
    max-height: ${(props) => (props.isPage ? "116px" : "164px")};
    border: 1px solid var(--grey-00);
    .thumbNail {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
    .empty__thumbnail {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--bg-03);
    }
  }

  @media only screen and (max-width: 768px) {
    border-bottom: 1px solid var(--grey-00);
    padding-bottom: 16px;
    margin-top: 16px;
    .thumbnail__container {
      width: 88px;
      min-width: 88px;
      max-width: 88px;
      height: 80px;
      min-height: 80px;
      max-height: 80px;
      object-fit: cover;
    }
  }
`;

const InfoContainer = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  .news__portfolio {
    color: var(--grey-50);
    margin-bottom: 4px;
  }
  .news__title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0px;
    margin-bottom: 4px;
    color: var(--black);
  }

  .news__description {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin: 0;
    margin-bottom: 6px;
    color: var(--grey-60);
  }
  .news__date {
    position: absolute;
    left: 32px;
    bottom: 0;
    color: var(--grey-30);
  }

  @media only screen and (max-width: 768px) {
    padding: 0px 10px;

    .news__portfolio {
      margin-bottom: 0px;
    }
    .news__title {
      font-size: 16px;
      margin-bottom: 0px;
    }
    .news__description {
      -webkit-line-clamp: 1;
      margin-bottom: 20px;
    }
    .news__date {
      color: var(--grey-30);
      left: 10px;
    }
  }
`;
export default React.memo(News);
