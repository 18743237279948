import styled from "@emotion/styled";
import DisplayType from "utils/displayType";
import { useLayoutEffect } from "react";
import { updateTitle } from "utils/commonUtil";
import Footer from "../../component/footer/Footer";

import ProgramRow, { ProgramRowProps } from "./components/ProgramRow";
import programBluelabs from "../../assets/program_bluelabs.png";
import programAlumni from "../../assets/program_alumni.png";
import programBlueSchool from "../../assets/program_blueschool.png";
import programOpenInnovation from "../../assets/program_openinnovation.png";
import programStartingPoint from "../../assets/program_startingpoint.png";

const Program = () => {
  const mode = DisplayType();

  const programItems: ProgramRowProps[] = [
    {
      to: "/program/start-point",
      subTitle: "창업을 시작하는 모든 사람들의 커뮤니티",
      title: "시작점",
      thumbNail: programStartingPoint,
      introduction: `야심찬 창업가들을 위한 다양한 네트워킹과 코워킹, 
      강연회와 이벤트가 이루어지는 커뮤니티 공간입니다.`,
      style: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPositionX: -200,
        // backgroundPositionY: -190,
      },
    },
    {
      to: "/program/alumni",
      subTitle: "同創, 함께할 동, 시작할 창: 함께 시작하다",
      title: "동창",
      thumbNail: programAlumni,
      introduction: `초기 스타트업 배치 프로그램으로, 
      선발된 팀들이 서로가 '동창'이 되어
      Peer-learning을 통해 함께 성장할 수 있는 프로그램입니다.`,
      style: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPositionX: -200,
        // backgroundPositionY: -190,
      },
    },
    {
      to: "/program/bluelabs",
      subTitle: "From Early to Zero Stage",
      title: "블루랩스",
      thumbNail: programBluelabs,
      introduction: `혁신가들과 함께 세상에 존재하는 ‘진짜 문제’를 창업으로 해결하고자 하는 컴퍼니빌딩 프로그램입니다.`,
      style: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPositionX: -200,
        // backgroundPositionY: -190,
      },
    },
    {
      to: "/program/blueschool",
      subTitle: "No Unicorn is born, It will only be made",
      title: "블루스쿨(Blue School)",
      thumbNail: programBlueSchool,
      introduction: `스타트업의 핵심 성장지표 분석을 통해
      실질적 밸류업을 달성하도록 케어하는 
      블루포인트 패밀리기업을 위한 집중 성장 프로그램입니다.`,
      style: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPositionX: -200,
        // backgroundPositionY: -190,
      },
    },
    {
      to: "/program/open-innovation",
      subTitle: "스타트업, 산업의 노하우를 만나다",
      title: "오픈이노베이션(Open Innovation)",
      thumbNail: programOpenInnovation,
      introduction: `신성장동력을 찾는 대기업/중견기업과
      산업 전문가가 필요한 스타트업을 연결하는 프로그램입니다.`,
      style: {
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        // backgroundPositionX: -200,
        // backgroundPositionY: -190,
      },
    },
  ];

  useLayoutEffect(() => {
    updateTitle("프로그램");
  }, []);

  return (
    <Container>
      <HeaderContainer
        className={`${mode === "Mobile" ? "font-bold-20" : "font-sub-title"}`}
      >
        스타트업 생태계를 위한
        <br />
        <div>
          <span className="blue__point">
            일하는 문화와 투자의 경험을 {mode === "Mobile" && <br />}혁신
          </span>
          해 나갑니다.
        </div>
      </HeaderContainer>
      <BodyContainer className="limit__body">
        {programItems.map((item, index) => {
          return (
            <ProgramRow key={index} item={item} isReverse={index % 2 === 1} />
          );
        })}
      </BodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 80px;

  @media only screen and (max-width: 768px) {
    padding-top: 60px;
  }
`;

const HeaderContainer = styled.div`
  width: 100%;
  height: 298px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-03);
  color: var(--grey-50);
  margin: 0;
  .blue__point {
    color: var(--primary);
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: fit-content;
    text-align: center;
    padding-top: 77px;
    padding-bottom: 77px;
  }
`;

const BodyContainer = styled.div`
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 180px;

  margin-top: 100px;
  margin-bottom: 160px;

  @media only screen and (max-width: 768px) {
    padding: 20px;
    padding-bottom: 80px;
    margin: 0;
    gap: 20px;
  }
`;

export default Program;
