import styled from "@emotion/styled";

export const NavigationLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;

  .navgation__container {
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    min-height: 100vh;
    max-height: 100vh;

    background-color: var(--primary);
    padding: 24px;
    overflow-y: scroll;

    .navigation {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .nav__title {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
      }

      .divider {
        width: 100%;
        height: 1px;
        background-color: white;
      }

      .nav__group {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      .nav__item {
        padding: 0 12px;
        width: 100%;
        height: 48px;
        min-height: 48px;
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        background-color: transparent;
        cursor: pointer;

        :hover {
          border-radius: 10px;
          background-color: var(--bs-info);
        }
      }
    }

    .accordion-item {
      background-color: transparent;
      border: none;
    }
    .accordion-button {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .contents__container {
    flex-grow: 1;
    min-height: 100vh;
    background-color: var(--white);
    overflow-y: scroll;
  }
`;
