import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import Footer from "component/footer/Footer";
import HeaderNavigation from "component/header/HeaderNavigation";
import axios from "axios";
import DisplayType from "utils/displayType";
import Banner from "component/banner/Banner";
import { Category, InsightMenu, NavigationMenu } from "admin/type/type";
import { useNavigate } from "react-router-dom";
import { ContentProps } from "admin/router/content";
import SearchBar from "component/search/SearchBar";
import Chip from "component/chips/Chip";
import { Pagination } from "@mui/material";
import DefaultButton from "admin/component/button/DefaultButton";
import { sortingByOpenDate, updateTitle } from "utils/commonUtil";
import * as api from "../../api/api";
import InsightTabs from "./components/InsightTabs";
import ContentsCard from "./components/ContentsCard";
import CategoryList from "./organism/CategoryList";
import contentBanner from "../../assets/content_banner.png";
import contentBanner1920 from "../../assets/content_banner_1920.png";
import contentBanner2048 from "../../assets/content_banner_2048.png";

export interface ContentStateProps {
  scrollY: number;
  currentPage: number;
}

const Contents = () => {
  const mode = DisplayType();
  const navigation = useNavigate();

  const isBack = useRef<boolean>(false);
  const [settingCurrentPage, updateSettingCurrentPate] =
    useState<boolean>(false);

  const countPerPage = useRef<number>(mode === "Mobile" ? 8 : 12);
  const [currentPage, updateCurrentPage] = useState<number>(1);
  const [totalPage, updateTotalPage] = useState<number>(0);

  const [allContents, updateAllContents] = useState<ContentProps[]>([]);
  const [contents, updateContents] = useState<ContentProps[]>([]);

  const [searchKeyword, updateSearchKeyword] = useState<string>("");
  const [selectedCategory, updateSelectedCategory] = useState<Category[]>([]);

  const onSearchContents = () => {
    let contentsList: ContentProps[] = [...allContents];

    if (selectedCategory.length === 0) {
      contentsList = [];
    }

    contentsList = contentsList.filter((item) => {
      return selectedCategory
        .map((category) => category.name)
        .includes(item.category);
    });

    contentsList = contentsList.filter((item) => {
      const keyword = searchKeyword.replace(/(\s*)/g, "").toUpperCase();
      return (
        item.title?.replace(/(\s*)/g, "").toUpperCase().includes(keyword) ||
        item.description?.replace(/(\s*)/g, "").toUpperCase().includes(keyword)
      );
    });

    updateContents([...contentsList]);
    updateTotalPage(Math.ceil(contentsList.length / countPerPage.current));
  };

  const getContentsList = async () => {
    try {
      const result = await axios.get(api.getContentList());
      if (result.status === 200) {
        const contentList = result.data
          .sort(sortingByOpenDate)
          .filter((item: any) => item.isActive)
          .map((item: any): ContentProps => ({ ...item }));

        updateAllContents(contentList);
        updateContents(contentList);
        updateTotalPage(Math.ceil(contentList.length / countPerPage.current));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getStartIndex = () => {
    return mode === "Mobile" ? 0 : (currentPage - 1) * countPerPage.current;
  };

  const storeState = (page?: number) => {
    const state: ContentStateProps = {
      scrollY: window.scrollY,
      currentPage: page || currentPage,
    };
    localStorage.setItem(document.location.pathname, JSON.stringify(state));
  };

  useEffect(() => {
    if (!settingCurrentPage) return;

    const state = localStorage.getItem(document.location.pathname);
    if (state) {
      const beforeState = { ...JSON.parse(state) };
      window.scrollTo(0, beforeState.scrollY);
      localStorage.removeItem(document.location.pathname);
      localStorage.removeItem("goBack");
    }
  }, [settingCurrentPage]);

  useEffect(() => {
    if (allContents.length === 0) return;

    const goBack = localStorage.getItem("goBack");
    if (goBack) {
      isBack.current = JSON.parse(goBack).goBack;
    }
    if (isBack.current) {
      const state = localStorage.getItem(document.location.pathname);
      if (state) {
        const beforeState = { ...JSON.parse(state) };
        updateCurrentPage(beforeState.currentPage);
        updateSettingCurrentPate(true);
      }
    }
  }, [allContents]);

  useEffect(() => {
    if (searchKeyword.length === 0) {
      onSearchContents();
    }
  }, [searchKeyword]);

  useEffect(() => {
    onSearchContents();
  }, [selectedCategory]);

  useEffect(() => {
    getContentsList();
  }, []);

  useLayoutEffect(() => {
    updateTitle("Contents");
  }, []);

  return (
    <Container>
      <Banner
        bannerImg={contentBanner}
        bannerImg1920={contentBanner1920}
        bannerImg2048={contentBanner2048}
        mode={mode}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: mode === "Mobile" ? "center" : "flex-start",
          }}
        >
          <div
            className={`${
              mode === "Mobile" ? "font-title-en" : "font-title-en"
            }`}
            style={{ color: "var(--white)" }}
          >
            CONTENTS
          </div>
        </div>
      </Banner>
      <InsightTabs selectedTab={InsightMenu.CONTENTS} />
      <InsightBodyContainer className="limit__body">
        <SearchBar
          onChage={(value) => {
            updateSearchKeyword(value);
          }}
          onSearching={onSearchContents}
        />
        <CategoryList
          onChange={(value) => {
            updateSelectedCategory(value);
          }}
        />
        <div className="content__list__container">
          {contents
            .slice(getStartIndex(), currentPage * countPerPage.current)
            .map((item) => {
              return (
                <ContentsCard
                  key={item.hpContentId}
                  item={item}
                  onClickItem={() => {
                    storeState();
                    navigation(`/insight/contents/${item.hpContentId}`);
                  }}
                />
              );
            })}
          {contents.length === 0 && (
            <SearchResultEmpty>
              {mode === "Mobile" ? (
                <div className="font-body3" style={{ textAlign: "center" }}>
                  {searchKeyword.length > 0 && `"${searchKeyword}"에 대한 `}
                  <br />
                  {"검색 결과가 없습니다."}
                </div>
              ) : (
                <span className="font-body3">
                  {searchKeyword.length > 0 && `"${searchKeyword}"에 대한`}
                  {` 검색 결과가 없습니다.`}
                </span>
              )}
            </SearchResultEmpty>
          )}
        </div>
        {mode !== "Mobile" && totalPage > 0 && (
          <div className="center">
            <Pagination
              count={totalPage}
              shape="rounded"
              page={currentPage}
              onChange={(event, page) => {
                storeState(page);
                updateCurrentPage(page);
              }}
            />
          </div>
        )}
        {mode === "Mobile" && totalPage > 0 && currentPage < totalPage && (
          <div className="center">
            <DefaultButton
              className="font-body3"
              type="outline"
              onClick={() => {
                storeState(currentPage + 1);
                updateCurrentPage(currentPage + 1);
              }}
            >
              컨텐츠 더보기
            </DefaultButton>
          </div>
        )}
      </InsightBodyContainer>
      <Footer />
    </Container>
  );
};
const Container = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
  }
`;
const InsightBodyContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;

  padding-top: 100px;
  padding-bottom: 100px;

  .category__list__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    margin-top: 56px;
    .category__list {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content__list__container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    margin-top: 56px;
    margin-bottom: 56px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 48px;
    padding-bottom: 48px;
    .category__list__container {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 40px;
      gap: 16px;
    }
    .content__list__container {
      margin-top: 40px;
    }
  }
`;
const SearchResultEmpty = styled.div`
  width: 100%;
  height: 100%;
  min-height: 276px;
  max-height: 276px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-top: 1px solid var(--grey-00);
  border-bottom: 1px solid var(--grey-00);
`;
export default Contents;
