import { Ogtag } from "admin/type/type";
import { Buffer } from "buffer";
import { Bounce, toast, ToastTransition } from "react-toastify";

export const NOT_EMAIL_FORM = "이메일 형식이 아닙니다.";
export const NOT_CAMPANY_EMAIL = "회사 이메일을 입력해주세요.";

export const checkNumber = (value: string): boolean => {
  const regExp = /^\d+$/;
  return regExp.test(value);
};
export const checkEmailForm = (email: string): boolean => {
  const regExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
  return regExp.test(email);
};

export const checkLanguageKo = (value: string): boolean => {
  const regExp = /[^ㄱ-힣0-9 ]/gi;
  return regExp.test(value);
};

export const checkLanguageEn = (value: string): boolean => {
  const regExp = /[^a-zA-Z0-9 ]/gi;
  return regExp.test(value);
};

export const getHM = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  const _date = typeof date === "string" ? new Date(date) : date;
  const h = _date.getHours();
  const m = _date.getMinutes();

  return `${h < 12 ? "오전" : "오후"} ${h < 12 ? h : h - 12}:${m}`;
};

export const getYMD = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  const _date = typeof date === "string" ? new Date(date) : date;
  const y = _date.getFullYear();
  const m = _date.getMonth() + 1;
  const d = _date.getDate();

  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d}`;
};

export const getYMDHM = (date: string | Date | undefined) => {
  if (!date) return "잘못된 날짜 유형입니다.";

  const _date = typeof date === "string" ? new Date(date) : date;
  const y = _date.getFullYear();
  const m = _date.getMonth() + 1;
  const d = _date.getDate();
  const h = _date.getHours();
  const _m = _date.getMinutes();

  return `${y}-${m < 10 ? `0${m}` : m}-${d < 10 ? `0${d}` : d} ${
    h < 10 ? `0${h}` : h
  }:${_m < 10 ? `0${_m}` : _m}`;
};
export const getYear = (date: string | Date | undefined) => {
  if (!date) return;

  const _date = typeof date === "string" ? new Date(date) : date;

  return _date.getFullYear();
};
export const getMonth = (date: Date) => date.getMonth();
export const getRangeYear = (
  startYear: number,
  endYear: number,
  increamentNumber: number
): number[] => {
  const _years: number[] = [];
  for (let i = startYear; i <= endYear; i++) {
    _years.push(i);
  }
  return _years;
};

export const OnlyNum = (value: string) => {
  value = value.replace(/[^0-9]/g, ""); // 입력값이 숫자가 아니면 공백
  value = value.replace(/,/g, ""); // ,값 공백처리
  return value;
};
export const RemoveComma = (num: string | number) => {
  if (typeof num === "number") num = `${num}`;

  return num.replace(/[^\d]+/g, "");
};
export const AddComma = (num: string | number) => {
  if (typeof num === "number") num = `${num}`;
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.replace(regexp, ",");
};

export const RegexpPhone = (num: string | number) => {
  if (typeof num === "number") num = `${num}`;
  let formatNum = "";

  if (num.length === 11) {
    formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3");
  }
  return formatNum;
};

export const RegexpBusiness = (num: string | number) => {
  if (typeof num === "number") num = `${num}`;

  let formatNum = "";

  if (num.length === 10) {
    formatNum = num.replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
  }
  return formatNum;
};

export const RemoveHypen = (num: string | number) => {
  if (typeof num === "number") num = `${num}`;

  return num.replace(/[^\d]+/g, "");
};

export const dateCalculator = (refDate: Date | string, offset: number) => {
  const date = new Date(refDate);
  return new Date(date.getFullYear(), date.getMonth(), date.getDate() + offset);
};

export const getRangeArray = (min: number, max: number): number[] => {
  const rangeArr: number[] = [];
  for (let i = min; i <= max; i++) {
    rangeArr.push(i);
  }
  return rangeArr;
};

export const getRoundNumber = (num: number, digit: number) => {
  let digitNumber = 1;
  for (let i = 1; i <= digit; i++) {
    digitNumber *= 10;
  }
  return Math.round((num + Number.EPSILON) * digitNumber) / digitNumber;
};

export const getFloatNum = (num: number, fixedNum: number) => {
  if (typeof num !== "number") return "0.00";

  return parseFloat(`${num}`).toFixed(fixedNum);
};

export const registeEntryPointToPortfolio = (
  entryPoint: "deal_flow" | "accelerating" | "database"
) => {
  const item = {
    value: entryPoint,
  };
  localStorage.removeItem("entryPoint");
  localStorage.setItem("entryPoint", JSON.stringify(item));
};
export const getEntryPointToPortfolio = (): string | null => {
  const itemStr = localStorage.getItem("entryPoint");
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  return item.value;
};

export const sortingByOpenDate = (a: any, b: any) => {
  const cDate1 = new Date(a.openDate);
  const cDate2 = new Date(b.openDate);
  const cDate1Time = cDate1.getTime();
  const cDate2Time = cDate2.getTime();

  if (cDate1Time > cDate2Time) return -1;
  if (cDate1Time === cDate2Time) return 0;
  if (cDate1Time < cDate2Time) return 1;
};

export const updateTitle = (title: string) => {
  if (!document) return;
  const htmlTitle = document.head.querySelector(`title`);
  if (htmlTitle) {
    htmlTitle.innerHTML = title;
  }
};

export const setMetaTags = (tag: Ogtag) => {
  const {
    title = "Bluepoint 블루포인트",
    description = "STARTING POINT FOR STARTERS, 혁신가들의 시도가 활발하게 이루어지는 사회를 꿈꿉니다.",
    image = "https://blue-storage.s3.ap-northeast-2.amazonaws.com/og.jpg",
    url = "https://bluepoint.ac",
  } = tag;
  if (!document) return;

  document.head
    .querySelector(`meta[property="og:title"]`)
    ?.setAttribute("content", `${title}`);
  document.head
    .querySelector(`meta[property="og:description"]`)
    ?.setAttribute("content", `${description}`);
  document.head
    .querySelector(`meta[property="og:image"]`)
    ?.setAttribute("content", `${image}`);
  document.head
    .querySelector(`meta[property="og:url"]`)
    ?.setAttribute("content", `${url}`);
};

export const transformObjectToBase64 = (object: any): string => {
  return Buffer.from(JSON.stringify(object), "utf8").toString("base64");
};

export const trnasfromBase64ToObject = (data: string): any => {
  return JSON.parse(Buffer.from(data, "base64").toString("utf8"));
};

export const getExtention = (type: string) => {
  switch (type) {
    case "application/pdf":
      return "pdf";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "text/csv":
      return "xls";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "docs";
    case "image/jpeg":
      return "jpg";
    case "image/png":
      return "png";
    case "application/zip":
      return "zip";
    default:
      return "file";
  }
};

export const showCustomToast = (data: {
  toastId?: string;
  customElement: JSX.Element;
  backgroundColor?: string;
  color?: string;
  width?: number;
  height?: number;
  milliSec?: number;
  position?:
    | "top-right"
    | "top-center"
    | "top-left"
    | "bottom-right"
    | "bottom-center"
    | "bottom-left";
  transition?: ToastTransition;
}) => {
  toast(data.customElement, {
    toastId: data.toastId || "custom-toast",
    autoClose: data.milliSec || 3000,
    style: {
      background: data.backgroundColor || "",
      color: data.color || "",
      width: data.width || "",
      height: data.height || "",
      minHeight: data.height || "",
      maxHeight: data.height || "",
      textAlign: "center",
    },
    bodyStyle: {
      margin: "0 auto",
    },
    hideProgressBar: true,
    closeButton: false,
    position: data.position || "bottom-right",
    transition: data.transition || Bounce,
  });
};
