import React, { useLayoutEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { NotionInput } from "admin/component/input/NotionInput";
import DefaultButton from "admin/component/button/DefaultButton";
import UploadImg from "admin/component/upload-img/UploadImg";
import { imgCategory } from "admin/type/type";
import { getYMD } from "utils/commonUtil";
import * as api from "../../../../api/api";
import BlueLetterCard from "./BannerCard";
import { BannerProps } from "../BannerAdmin";

interface Props {
  item?: BannerProps;
  close?: () => void;
  callback?: () => void;
}

const BannerDetail: React.FC<Props> = (props) => {
  const [openDate, updateOpenDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [closeDate, updateCloseDate] = useState<string | undefined>(
    getYMD(new Date())
  );
  const [linkUrl, updateLinkUrl] = useState<string | undefined>(undefined);
  const [thumbNailImgKey, updateThumbNailImgKey] = useState<string | undefined>(
    undefined
  );
  const [thumbNailImgUrl, updateThumbNailImgUrl] = useState<string | undefined>(
    undefined
  );

  const hpBannerId = useRef<number | undefined>(undefined);
  const isActive = useRef<boolean>(true);

  const getBlueletterDetail = () => {
    if (props.item === undefined) return;

    isActive.current = props.item.isActive;
    hpBannerId.current = props.item.hpBannerId;

    updateOpenDate(getYMD(props.item.openDate));
    updateCloseDate(getYMD(props.item.closeDate));
    updateLinkUrl(props.item.linkUrl);
    updateThumbNailImgKey(props.item.thumbNailImgKey);
    updateThumbNailImgUrl(props.item.thumbNailImgUrl);
  };

  const updateBlueletter = async () => {
    try {
      const param = {
        hpBannerId: hpBannerId.current,
        openDate,
        closeDate,
        linkUrl,
        thumbNailImgKey,
        thumbNailImgUrl,
        isActive: isActive.current,
      };

      axios.put(api.updateBanner(), param).then((result) => {
        if (result.status === 200 && props.callback) {
          props.callback();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createBlueletter = async () => {
    try {
      const param = {
        openDate,
        closeDate,
        linkUrl,
        thumbNailImgKey,
        thumbNailImgUrl,
        isActive: true,
      };

      axios.post(api.createBanner(), param).then((result) => {
        if (result.status === 201 && props.callback) {
          props.callback();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useLayoutEffect(() => {
    if (props.item !== undefined) {
      getBlueletterDetail();
    }
  }, [props.item]);

  return (
    <Container>
      <div className="banner__detail__header font-bold-18">
        <span />
        {props.item ? "배너 수정" : "배너 추가"}
        <DefaultButton
          disabled={
            openDate === undefined ||
            closeDate === undefined ||
            thumbNailImgUrl === undefined ||
            linkUrl === undefined
          }
          onClick={() => {
            if (props.item) {
              // 수정
              updateBlueletter();
            } else {
              // 생성
              createBlueletter();
            }
          }}
        >
          {props.item ? "수정하기" : "생성하기"}
        </DefaultButton>
      </div>
      <div className="banner__detail__body">
        <div className="banner__detail__body__row">
          <UploadImg
            editable
            category={imgCategory.BANNER}
            imgUrl={thumbNailImgUrl}
            onChangeFile={(url, key) => {
              updateThumbNailImgKey(key);
              updateThumbNailImgUrl(url);
            }}
            width={458}
            height={212}
          />
        </div>

        <div className="banner__detail__body__row">
          <NotionInput
            label="게시일"
            hasUnderLine
            editable
            textType="date"
            max="9999-12-31"
            placeholder="연도. 월. 일"
            defaultValue={openDate}
            onChange={(e) => {
              updateOpenDate(e);
              updateCloseDate(e);
            }}
          />
          <NotionInput
            label="종료일"
            hasUnderLine
            editable
            textType="date"
            min={openDate}
            max="9999-12-31"
            placeholder="연도. 월. 일"
            defaultValue={closeDate}
            onChange={(e) => {
              updateCloseDate(e);
            }}
          />
        </div>
        <div className="banner__detail__body__row">
          <NotionInput
            label="링크"
            hasUnderLine
            editable
            textType="text"
            defaultValue={linkUrl}
            onChange={(e) => {
              updateLinkUrl(e);
            }}
          />
        </div>
      </div>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  max-width: 780px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  .banner__detail__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 10px 24px;

    border-bottom: 1px solid var(--grey);
  }
  .banner__detail__body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 10px;

    .banner__detail__body__row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }
  }
`;
export default BannerDetail;
