import React from "react";
import { Link } from "react-router-dom";
import { InsightMenu, NavigationMenu } from "admin/type/type";

import styled from "@emotion/styled";

import { ReactComponent as GoLink } from "../../assets/icon_golink_blue.svg";

interface NavigationTheme {
  selected: NavigationMenu;
  isSubMenuOpen: boolean;
  isFillNavBackground: boolean;
  refreshPage: (selectedNavigation: NavigationMenu) => void;
}
const NavigationsNew: React.FC<NavigationTheme> = ({
  selected,
  isSubMenuOpen,
  isFillNavBackground,
  refreshPage,
}) => {
  const navigationItemStyle: React.CSSProperties = {
    textDecoration: "none",
  };

  const onClickInsight = (value: InsightMenu, selectedMenu: NavigationMenu) => {
    localStorage.setItem("insightMenu", value);
    if (refreshPage) refreshPage(selectedMenu);
  };

  const getIsSelected = (menu: NavigationMenu) => {
    switch (menu) {
      case NavigationMenu.ABOUT:
        return (
          selected === NavigationMenu.ABOUT ||
          selected === NavigationMenu.COMPANY ||
          selected === NavigationMenu.TEAM ||
          selected === NavigationMenu.TEAMDETAIL
        );

      case NavigationMenu.COMPANY:
        return selected === NavigationMenu.COMPANY;
      case NavigationMenu.PROGRAM:
        return (
          selected === NavigationMenu.PROGRAM ||
          selected === NavigationMenu.STARTINGPOINT ||
          selected === NavigationMenu.ALUMNI ||
          selected === NavigationMenu.BLUELABS ||
          selected === NavigationMenu.BLUESCHOOL ||
          selected === NavigationMenu.OPENINNOVATION
        );
      case NavigationMenu.STARTINGPOINT:
        return selected === NavigationMenu.STARTINGPOINT;
      case NavigationMenu.ALUMNI:
        return selected === NavigationMenu.ALUMNI;
      case NavigationMenu.BLUELABS:
        return selected === NavigationMenu.BLUELABS;
      case NavigationMenu.BLUESCHOOL:
        return selected === NavigationMenu.BLUESCHOOL;
      case NavigationMenu.OPENINNOVATION:
        return selected === NavigationMenu.OPENINNOVATION;
      case NavigationMenu.TEAM:
        return (
          selected === NavigationMenu.TEAM ||
          selected === NavigationMenu.TEAMDETAIL
        );
      case NavigationMenu.PORTFOLIO:
        return (
          selected === NavigationMenu.PORTFOLIO ||
          selected === NavigationMenu.PORTFOLIODETAIL
        );
      case NavigationMenu.INSIGHT:
        return (
          selected === NavigationMenu.INSIGHT ||
          selected === NavigationMenu.EVENTS ||
          selected === NavigationMenu.EVENTSDETAIL ||
          selected === NavigationMenu.CONTENTS ||
          selected === NavigationMenu.CONTENTSDETAIL ||
          selected === NavigationMenu.NEWS ||
          selected === NavigationMenu.BLUELETTER
        );
      case NavigationMenu.EVENTS:
        return (
          selected === NavigationMenu.EVENTS ||
          selected === NavigationMenu.EVENTSDETAIL
        );
      case NavigationMenu.CONTENTS:
        return (
          selected === NavigationMenu.CONTENTS ||
          selected === NavigationMenu.CONTENTSDETAIL
        );
      case NavigationMenu.NEWS:
        return selected === NavigationMenu.NEWS;
      case NavigationMenu.BLUELETTER:
        return selected === NavigationMenu.BLUELETTER;
      case NavigationMenu.CONTACT:
        return (
          selected === NavigationMenu.CONTACT ||
          selected === NavigationMenu.APPLY ||
          selected === NavigationMenu.PARTNERSHIP ||
          selected === NavigationMenu.FAQ
        );
      case NavigationMenu.APPLY:
        return selected === NavigationMenu.APPLY;
      case NavigationMenu.PARTNERSHIP:
        return selected === NavigationMenu.PARTNERSHIP;
      case NavigationMenu.FAQ:
        return selected === NavigationMenu.FAQ;
      case NavigationMenu.IR:
        return (
          selected === NavigationMenu.DISCLOSURE_INFOMATION ||
          selected === NavigationMenu.STOCK_INFOMATIOIN ||
          selected === NavigationMenu.IR_DATA ||
          selected === NavigationMenu.ANNOUNCEMENT ||
          selected === NavigationMenu.ANNOUNCEMENTDETAIL
        );
      case NavigationMenu.DISCLOSURE_INFOMATION:
        return selected === NavigationMenu.DISCLOSURE_INFOMATION;
      case NavigationMenu.STOCK_INFOMATIOIN:
        return selected === NavigationMenu.STOCK_INFOMATIOIN;
      case NavigationMenu.IR_DATA:
        return selected === NavigationMenu.IR_DATA;
      case NavigationMenu.ANNOUNCEMENT:
        return (
          selected === NavigationMenu.ANNOUNCEMENT ||
          selected === NavigationMenu.ANNOUNCEMENTDETAIL
        );

      default:
        return false;
    }
  };
  return (
    <Container>
      <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.ABOUT)}
      >
        <Link
          to={"/about"}
          style={navigationItemStyle}
          onClick={() => refreshPage && refreshPage(NavigationMenu.ABOUT)}
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>
            About
          </div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}>
          <Link
            to={"/about"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.ABOUT)}
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.COMPANY)}
            >
              회사 소개
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/team"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.TEAM)}
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.TEAM)}
            >
              팀 소개
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem>
      <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.PROGRAM)}
      >
        <Link
          to={"/program"}
          style={navigationItemStyle}
          onClick={() => refreshPage && refreshPage(NavigationMenu.PROGRAM)}
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>
            Program
          </div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}>
          <Link
            to={"/program/start-point"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.STARTINGPOINT)
            }
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.STARTINGPOINT)}
            >
              시작점
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/program/alumni"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.ALUMNI)}
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.ALUMNI)}
            >
              동창
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/program/bluelabs"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.BLUELABS)}
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.BLUELABS)}
            >
              블루랩스
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/program/blueschool"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.BLUESCHOOL)
            }
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.BLUESCHOOL)}
            >
              블루스쿨
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/program/open-innovation"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.OPENINNOVATION)
            }
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.OPENINNOVATION)}
            >
              오픈이노베이션
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem>
      {/* <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.TEAM)}
      >
        <Link
          to={"/team"}
          style={navigationItemStyle}
          onClick={() => refreshPage && refreshPage(NavigationMenu.TEAM)}
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>
            Team
          </div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}>
          <Link
            to={"/team"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.TEAM)}
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.TEAM)}
            >
              팀 소개
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem> */}
      <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.PORTFOLIO)}
      >
        <Link
          to={"/portfolio"}
          style={navigationItemStyle}
          onClick={() => refreshPage && refreshPage(NavigationMenu.PORTFOLIO)}
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>
            Portfolio
          </div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}>
          <Link
            to={"/portfolio"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.PORTFOLIO)}
          >
            <NavSubMenuItem
              className="font-button2"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.PORTFOLIO)}
            >
              포트폴리오 소개
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem>
      <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.INSIGHT)}
      >
        <Link
          to={"/insight"}
          style={navigationItemStyle}
          onClick={() =>
            onClickInsight(InsightMenu.ALL, NavigationMenu.INSIGHT)
          }
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>
            Insight
          </div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}>
          <Link
            to={"/insight/events"}
            style={navigationItemStyle}
            onClick={() =>
              onClickInsight(
                InsightMenu.EVENTS,

                NavigationMenu.EVENTS
              )
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.EVENTS)}
            >
              Events
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/insight/contents"}
            style={navigationItemStyle}
            onClick={() =>
              onClickInsight(
                InsightMenu.CONTENTS,

                NavigationMenu.CONTENTS
              )
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.CONTENTS)}
            >
              Contents
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/insight/news"}
            style={navigationItemStyle}
            onClick={() =>
              onClickInsight(
                InsightMenu.NEWS,

                NavigationMenu.NEWS
              )
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.NEWS)}
            >
              News
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/insight/blueletter"}
            style={navigationItemStyle}
            onClick={() =>
              onClickInsight(
                InsightMenu.BLUELETTER,

                NavigationMenu.BLUELETTER
              )
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.BLUELETTER)}
            >
              Blue Letter
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem>
      <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.CONTACT)}
      >
        <Link
          to={"/contact/apply"}
          style={navigationItemStyle}
          onClick={() => refreshPage && refreshPage(NavigationMenu.APPLY)}
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>
            Contact
          </div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}>
          <Link
            to={"/contact/apply"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.APPLY)}
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.APPLY)}
            >
              Apply
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/contact/partnership"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.PARTNERSHIP)
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.PARTNERSHIP)}
            >
              Partnership
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/contact/faq"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.FAQ)}
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.FAQ)}
            >
              FAQ
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem>
      {/* <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={getIsSelected(NavigationMenu.IR)}
      >
        <Link
          // to={"/ir/disclosure-info"}
          to="/ir/announcement"
          style={navigationItemStyle}
          onClick={() =>
            refreshPage && refreshPage(NavigationMenu.DISCLOSURE_INFOMATION)
          }
        >
          <div className={`nav__item ${isSubMenuOpen && "menu__open"}`}>IR</div>
        </Link>
        <NavSubMenuContainer className={`${isSubMenuOpen && "visible"}`}> */}
      {/* <Link
            to={"/ir/disclosure-info"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.DISCLOSURE_INFOMATION)
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.DISCLOSURE_INFOMATION)}
            >
              공시정보
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/ir/stock-info"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.STOCK_INFOMATIOIN)
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.STOCK_INFOMATIOIN)}
            >
              주가정보
            </NavSubMenuItem>
          </Link>
          <Link
            to={"/ir/ir-archive"}
            style={navigationItemStyle}
            onClick={() => refreshPage && refreshPage(NavigationMenu.IR_DATA)}
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.IR_DATA)}
            >
              IR자료실
            </NavSubMenuItem>
          </Link> */}
      {/* <Link
            to={"/ir/announcement"}
            style={navigationItemStyle}
            onClick={() =>
              refreshPage && refreshPage(NavigationMenu.ANNOUNCEMENT)
            }
          >
            <NavSubMenuItem
              className="font-button2-en"
              isFillNavBackground={isFillNavBackground}
              isSelected={getIsSelected(NavigationMenu.ANNOUNCEMENT)}
            >
              공고
            </NavSubMenuItem>
          </Link>
        </NavSubMenuContainer>
      </NavItem> */}
      <NavItem
        className={`font-button1`}
        isFillNavBackground={isFillNavBackground}
        isSelected={false}
      >
        <a href="https://bluecareer.co.kr/" target={"_blank"} rel="noreferrer">
          <div
            className={`nav__item ${isSubMenuOpen && "menu__open"}`}
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 4,
              alignItems: "center",
            }}
          >
            Recruit
            <GoLink className="icon-golink" />
          </div>
        </a>
      </NavItem>
    </Container>
  );
};

const Container = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li<{
  isSelected: boolean;
  isFillNavBackground: boolean;
}>`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  .nav__item {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: ${(props) =>
      props.isSelected
        ? "var(--primary)"
        : props.isFillNavBackground
        ? "var(--black)"
        : "var(--white)"};
    transition: color 0.3s ease;
    :hover {
      color: var(--primary);
      .icon-golink {
        visibility: visible;
      }
    }
    .icon-golink {
      visibility: hidden;
    }
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: var(--mobile)) {
    color: black;
  }
`;

const NavSubMenuContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 106px;
  left: 0;
  display: none;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  z-index: 10;
  opacity: 0;
  transition: all 0.3s ease;
  &.visible {
    opacity: 1;
    display: flex;
  }
`;
const NavSubMenuItem = styled.div<{
  isSelected: boolean;
  isFillNavBackground: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => (props.isSelected ? "var(--primary)" : "var(--grey-60)")};
  cursor: pointer;
  :hover {
    color: var(--primary);
  }
`;
export default NavigationsNew;
